import React, { Component } from "react";
import {
    Button,
    Col,
    Collapse,
    DatePicker,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Slider,
    Upload, message,
} from "antd";
import "../Projects/myprojects.scss";
import { PlusCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import "@lourenci/react-kanban/dist/styles.css";
import { ReactComponent as Arrow } from "../../assets/images/icons/arrow.svg";
import {ReactComponent as Close} from "../../assets/images/icons/close_modal.svg";
import ProjectsMap from "../ProjectsMap/projectsMap";
import "leaflet/dist/leaflet.css";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import { addFile, BackendUrl, getMembers, getProjects, postTasks, putTask, deleteFile } from "../../actions/mainActions";
import moment from "moment";
import './tasks.scss'
import { ModalContragent } from "../Projects/createEditProjects";
import { cleanFormat } from "@amcharts/amcharts4/.internal/core/utils/Utils";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;

export class TaskAddEdit extends Component {
    state = {
        projectsList: [],
        filesList: [],
        allMembers: [],
        showMap: false,
        tasks: [],
        loading: false,
        existPoints: [],
        mapObjectsArr: [],
        selectContragentsModalIsActive: false,
        roleMember: '',
        managerUsers: [],
        memberUsers: []
    }
    form = React.createRef();

    setFieldsValue(name, value) {
        this.form.current &&
          this.form.current.setFieldsValue({ [name]: value });
    }

    handleOkAdd = (e) => {
        const objToSave = e;
        objToSave.type = "task";
        objToSave.start = moment(e.end[0]).format("YYYY-MM-DD");
        objToSave.end = moment(e.end[1]).format("YYYY-MM-DD");
        objToSave.mapObjectsArr = this.state.mapObjectsArr;
        objToSave.filesArr = [];
        this.state.filesList.forEach(el => {
            objToSave.filesArr.push(el.response)
        });
        this.setState({
            loading: true
        })
        postTasks(objToSave).then((res) => {
            message.success("Задача успешно создана");
            this.setState({
                isVisible: false,
                loading: false,
                filesList: []
            });
            this.form.current.resetFields();
            this.props.hideModal();
        });
    }

    handleOkeditId = (e) => {
        const { editId } = this.props;
        const objToSave = e;
        objToSave.type = "task";
        objToSave.start = moment(e.end[0]).format("YYYY-MM-DD");
        objToSave.end = moment(e.end[1]).format("YYYY-MM-DD");
        objToSave.mapObjectsArr = this.state.mapObjectsArr;
        objToSave.filesArr = [];
        this.state.filesList.forEach(el => {
            objToSave.filesArr.push(el.response)
        });
        this.setState({
            loading: true
        })
        putTask(objToSave, editId).then((res) => {
            message.success("Задача успешно обновлена");
            this.setState({
                loading: false,
                isVisible: false,
                filesList: [],
                showMap: false,
            });
            this.props.hideModal();
            window.location.reload();
        });
    }


    projectChange = (e) => {
        const projects = [...this.state.projectsList];
        const project = projects.find(el => el.id === e)
        const tasks = project ? project.tasks : [];
        this.setState({
            tasks
        });
    };

    componentDidMount() {
        const { editId } = this.props;
        // this.getAllMembers();
        this.getAllProjects();
        if (editId) {
            this.fillForm();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { editId } = this.props;
        if (prevProps.editId !== editId) {
            // this.getAllMembers();
            this.getAllProjects();
            if (editId) {
                this.fillForm();
            }
        }
        // if(prevState.filesList !== this.state.filesList){
        // debugger
        // this.setState((s)=>({
        //     ...s,
        //     filesList: this.state.filesList
        // }))
        // }
    }

    fillForm = () => {
        const formValues = { ...this.props.taskDetails };
        const filesList = this.props.taskDetails.files.length > 0 ? this.props.taskDetails.files.map(el => {
            return {
                uid: el.id,
                response: el.id,
                name: el.name,
                status: 'done',
                showMap: false,
                url: BackendUrl + el.src,
            }
        }) : [];
        formValues.task_project_id = this.props.taskDetails.task_project_id ? Number(this.props.taskDetails.task_project_id) : null;
        formValues.parent_task_id = this.props.taskDetails.parent_task_id ? Number(this.props.taskDetails.parent_task_id) : null;
        formValues.task_project_id && this.projectChange(formValues.task_project_id);
        formValues.managers = this.props.taskDetails.managers.map(el => el.id);
        formValues.members = this.props.taskDetails.members.map(el => el.id);
        formValues.end = [moment(this.props.taskDetails.start), moment(this.props.taskDetails.end)]
        this.form.current.setFieldsValue({ ...formValues });
        const mapObjectsArr = [...this.props.taskDetails.map_objects];
        this.setState({
            filesList,
            existPoints: this.props.taskDetails.map_objects,
            mapObjectsArr: mapObjectsArr.map(el => el.id),
            allMembers: [...this.props.taskDetails.members, ...this.props.taskDetails.managers],
            managerUsers: this.props.taskDetails.managers,
            memberUsers: this.props.taskDetails.members
        })
    }

    setMapObjectsArr = (arr) => {
        this.setState({
            mapObjectsArr: arr
        });
    };

    getAllProjects = () => {
        const { editId } = this.props;
        getProjects().then(res => {
            const projectsList = editId ? res.data.data.filter(el => el.id !== editId) : res.data.data;
            this.setState({
                projectsList
            })
        })
    }

    uploadFile = ({ onSuccess, onError, file }) => {
        // debugger
        const formData = new FormData();
        formData.append("files[]", file);
        addFile(formData).then(res => {
            console.log("res of add File",res)
            const filesList = this.state.filesList;
            filesList.push({
                uid: res.data.data.ids[0],
                name: file.name,
                status: "done",
                response: res.data.data.ids[0],
                url: BackendUrl + res.data.data.src[0],
            });
            this.setState((s)=>({
                ...s,
                filesList
            }));
        });
    };

    // getAllMembers = () => {
    //     getMembers().then(res => {
    //         const allMembers = res.data.data;
    //         console.log(allMembers)
    //         this.setState({
    //             allMembers
    //         })
    //     }
    //     );
    // }

    removeDocuments = (e) => {
        const filesList = [...this.state.filesList];
        const index = filesList.findIndex(el => el.uid === e.uid);
        deleteFile(e.uid).then(res => {
            // debugger
            console.log(res,"res")
            if (index > -1) {
                filesList.splice(index, 1);
            }
            this.setState({
                filesList
            })
        })
    }

    // onFileChange = (fileList) => {
    //     debugger
    //     this.setState({ filesList: [...fileList] });
    // }

    showSelectContragentsModal = (role) => {
        this.setState({
          selectContragentsModalIsActive: true,
          roleMember: role,
        });
    };
    hideSelectContragentsModal = () => {
        this.setState({
          selectContragentsModalIsActive: false,
        });
    };
    getContractorUsersData = (selectedUsers) => {
        switch (this.state.roleMember) {
          case "manager":
            this.setState({
                managerUsers: [...this.state.managerUsers, ...selectedUsers.filter(item => this.state.managerUsers.filter(el => (el.key ?? el.id) === item.key).length === 0)]
            });
            this.setFieldsValue(
              "managers",
              this.state.managerUsers.map((e) => e.key ?? e.id)
            );
            break;
          case "member":
            this.setState({
                memberUsers: [...this.state.memberUsers, ...selectedUsers.filter(item => this.state.memberUsers.filter(el => (el.key ?? el.id) === item.key).length === 0)]
            });
            this.setFieldsValue(
              "members",
              this.state.memberUsers.map((e) => e.key ?? e.id)
            );
            break;
          default:
            return;
        }
      };

    render() {
        const { projectsList, filesList, allMembers, showMap, tasks, existPoints, loading, mapObjectsArr } = this.state;
        const { hideModal, editId, isVisible } = this.props;
        return (
            <Modal
                title={editId ? "Редактировать задачу" : "Добавить задачу"}
                visible={isVisible}
                width={"80%"}
                footer={null}
                onOk={hideModal}
                onCancel={hideModal}
                className="task-add-edit-modal"
                closeIcon={<Close style={{marginTop: '22px'}}/>}
            >
                <Form
                    ref={this.form}
                    name="basic"
                    onFinish={editId ? this.handleOkeditId : this.handleOkAdd}
                >
                    <Row gutter={12} style={{ marginBottom: "25px" }}>
                        <Col span={4}>
                            <h3>Название</h3>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Введите название задачи"
                                    }
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder={"Введите название задачи"}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ marginBottom: "25px" }}>
                        <Col span={4}>
                            <h3>Описание</h3>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Введите описание задачи"
                                    }
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder="Введите описание задачи"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ marginBottom: "25px" }}>
                        <Col span={4}>
                            <h3> Проект </h3>
                        </Col>
                        <Col span={20}>
                            <Form.Item name="task_project_id">
                                <Select size="large" placeholder="Выберите проект"
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    onChange={this.projectChange}
                                    showArrow={false}
                                >
                                    {
                                        projectsList.map((item) => (
                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ marginBottom: "25px" }}>
                        <Col span={4}>
                            <h3>Файл</h3>
                        </Col>
                        <Col span={20}>
                            <Upload
                                onRemove={this.removeDocuments}
                                listType="text"
                                fileList={filesList}
                                customRequest={this.uploadFile}
                                onChange={this.onFileChange}
                            >
                                {filesList.length >= 8 ? null :
                                    <Button className={"pl-0"} size="large"
                                        type={"link"}>
                                        + Загрузить файл
                                    </Button>}
                            </Upload>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "25px" }}>
                        <Col span={4}>
                            <h3>
                                Ответственный
                            </h3>
                        </Col>
                        <Col span={20} className="contragents-select">
                            <Button
                                className={"pl-0"}
                                size="large"
                                type={"link"}
                                style={{ width: "fit-content" }}
                                onClick={() => {
                                    this.showSelectContragentsModal("member");
                                }}
                            >
                            + Добавить ответственных
                            </Button>
                            <Form.Item
                                name="members"
                                rules={[
                                    {
                                        required: true,
                                        message: "Выберите ответственного"
                                    }
                                ]}
                            >
                                <Select
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    mode="multiple"
                                    placeholder="Выберите ответственного"
                                    size="large"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    dropdownRender={() => (<></>)}
                                    tagRender={props => (
                                        <div className="contragent-card" >
                                            <span>{props.label}</span>
                                            {/* <span className='contragent-company'>{props.label[4]}</span> */}
                                            <button className="contragent-card-close" onClick={props.onClose} style={{}}><Close style={{fill: '#3C4E77'}}/></button>
                                        </div>
                                    )}
                                    className="disabled-select"
                                >
                                    {this.state.memberUsers.map((item) => (
                                        <Option value={item.key ? item.key : item.id} key={item.key ? item.key : item.id}>
                                            { item.name ? <span> {item.name} </span> : <span> {item.first_name + ' ' + item.last_name }</span>}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "25px" }}>
                        <Col span={4}>
                            <h3>
                                Постановщик
                            </h3>
                        </Col>
                        <Col span={20} className="contragents-select">
                            <Button
                                className={"pl-0"}
                                size="large"
                                type={"link"}
                                style={{ width: "fit-content" }}
                                onClick={() => {
                                    this.showSelectContragentsModal("manager");
                                }}
                            >
                            + Добавить постановщиков
                            </Button>
                            <Form.Item
                                name="managers"
                                rules={[
                                    {
                                        required: true,
                                        message: "Выберите постановщика"
                                    }
                                ]}
                            >
                                <Select
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    mode="multiple"
                                    placeholder="Выберите постановщика"
                                    size="large"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    tagRender={props => (
                                        <div className="contragent-card" >
                                            <span>{props.label}</span>
                                            {/* <span className='contragent-company'>{props.label[4]}</span> */}
                                            <button className="contragent-card-close" onClick={props.onClose} style={{}}><Close style={{fill: '#3C4E77'}}/></button>
                                        </div>
                                    )}
                                    dropdownRender={() => (<></>)}
                                    filterOption={(input, option) =>
                                        option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    className="disabled-select"
                                >
                                    {this.state.managerUsers.map((item) => (
                                        <Option value={item.key ? item.key : item.id} key={item.key ? item.key : item.id}>
                                            { item.name ? <span> {item.name} </span> : <span> {item.first_name + ' ' + item.last_name }</span>}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <h3>
                                Срок проекта
                            </h3>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                name="end"
                                rules={[
                                    {
                                        required: true,
                                        message: "Укажите сроки проекта"
                                    }
                                ]}
                            >
                                <RangePicker
                                    locale={locale}
                                    size="large"
                                    format="DD.MM.YYYY"
                                    placeholder={['С', 'По']}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <h3>Приоритет</h3>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                name="priority"
                                rules={[
                                    {
                                        required: true,
                                        message: "Укажите приоритет"
                                    }
                                ]}>
                                <Select
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    size="large"
                                    placeholder="Выберите приоритет"
                                    style={{ width: "100%" }}
                                    showArrow={false}
                            >
                                    <Option value="normal">Нормальный</Option>
                                    <Option value="critical">Критичный</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ marginBottom: '25px', alignItems: 'center' }}>
                        <Col span={4}>
                            <h3>
                                Процент выполнения{" "}
                            </h3>{" "}
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                name="perform_percent"
                            >
                                <Slider />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ marginBottom: "25px", marginTop: '15px' }}>
                        <Col span={4}>
                            <h3> Сделать подзадачей</h3>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="parent_task_id">
                                <Select
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    size="large"
                                    allowClear
                                    placeholder="Выберите под задачу"
                                    style={{ width: "100%" }}
                                    showArrow={false}
                            >
                                    {tasks.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Collapse defaultActiveKey={["1"]} ghost>
                        <Panel header="Дополнительно" key="1">
                            <Row gutter={12} style={{ marginBottom: "25px" }}>
                                <Col span={4}>
                                    <h3>Карта</h3>
                                </Col>
                                <Col span={4}>
                                    <Switch checked={showMap} onChange={(e) => {
                                        this.setState({ showMap: e });
                                    }} />
                                </Col>
                            </Row>

                            {showMap &&
                                <ProjectsMap
                                    setMapObjectsArr={this.setMapObjectsArr} existPoints={existPoints} mapObjectsArr={mapObjectsArr} />}


                            <Row gutter={12} style={{ marginBottom: "25px" }}>
                                <Col span={4}>
                                    <h3> Гант </h3>
                                </Col>
                                <Col span={8}>Добавить предшествующую задачу</Col>
                            </Row>
                        </Panel>
                    </Collapse> */}
                    <Row className={"modalFooter"}>
                        <Col span={8}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={loading}
                                    style={{ width: "80%", }}
                                >
                                    {this.props.editId ? <div>Обновить задачу</div> :
                                        <div>Создать задачу <PlusCircleOutlined /></div>}

                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        hideModal();
                                    }}

                                    type="link"
                                    size="large"
                                >
                                    ОТМЕНА
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <ModalContragent
                    selectContragentsModalIsActive={this.state.selectContragentsModalIsActive}
                    showSelectContragentsModal={this.showSelectContragentsModal}
                    hideSelectContragentsModal={this.hideSelectContragentsModal}
                    getContractorUsersData={this.getContractorUsersData}
                />
            </Modal>
        )
    }
}
