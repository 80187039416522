import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4ThemeAnimated from "@amcharts/amcharts4/themes/animated";
import './styles.css'

am4core.useTheme(am4ThemeAnimated);

export default function LineChart() {
  const chart = am4core.create("chart", am4charts.XYChart);
  chart.paddingRight = 20;

  // Add data
  chart.data = [
    {
      day: "1",
      value: 140,
    },
    {
      day: "2",
      value: 150,
    },
    {
      day: "3",
      value: 160,
    },
    {
      day: "4",
      value: 170,
    },
    {
      day: "5",
      value: 180,
    },
    {
      day: "6",
      value: 175,
    },
    {
      day: "7",
      value: 170,
    },
    {
      day: "8",
      value: 165,
    },
    {
      day: "9",
      value: 160,
    },
    {
      day: "10",
      value: 167,
    },
    {
      day: "11",
      value: 175,
    },
    {
      day: "12",
      value: 183,
    },
    {
      day: "13",
      value: 192,
    },
    {
      day: "14",
      value: 210,
    },
    {
      day: "15",
      value: 215,
    },
    {
      day: "16",
      value: 230,
    },
    {
      day: "17",
      value: 230,
    },
    {
      day: "18",
      value: 220,
    },
    {
      day: "19",
      value: 210,
    },
    {
      day: "20",
      value: 215,
    },
    {
      day: "21",
      value: 220,
    },
    {
      day: "22",
      value: 220,
    },
    {
      day: "23",
      value: 220,
    },
    {
      day: "24",
      value: 220,
    },
    {
      day: "24",
      value: 220,
    },
    {
      day: "25",
      value: 220,
    },
    {
      day: "26",
      value: 220,
    },
    {
      day: "28",
      value: 220,
    },
    {
      day: "29",
      value: 220,
    },
    {
      day: "30",
      value: 220,
    },
  ];

  // Create axes
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "day";
  // categoryAxis.renderer.minGridDistance = 50
  categoryAxis.renderer.grid.template.location = 0.5;
  categoryAxis.startLocation = 0.5;
  categoryAxis.endLocation = 0.5;
  categoryAxis.renderer.grid.template.strokeOpacity = 0;
  categoryAxis.renderer.labels.template.fillOpacity = 0.3;

  // Create value axis
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.baseValue = 0;
  valueAxis.renderer.minGridDistance = 50;
  valueAxis.renderer.labels.template.fillOpacity = 0.3;

  // Create series
  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = "value";
  series.dataFields.categoryX = "day";
  series.strokeWidth = 2;
  series.tensionX = 0.8;
  series.tensionY = 0.3;
  series.fillOpacity = 1;

  const fillModifier = new am4core.LinearGradientModifier();
  fillModifier.opacities = [0.2, 0];
  fillModifier.offsets = [0, 0.5];
  fillModifier.gradient.rotation = 90;
  series.segments.template.fillModifier = fillModifier;

  // bullet is added because we add tooltip to a bullet for it to change color
  const bullet = series.bullets.push(new am4charts.Bullet());
  // bullet.tooltipText = 'За месяц\n{valueY}\nСентябрь {day}'
  series.tooltip.background.cornerRadius = 8;
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = am4core.color("#fff");
  series.tooltip.background.strokeOpacity = 0;
  series.tooltip.background.fillOpacity = 1;
  bullet.tooltipHTML =
    '<table><tr><th style="color: #A0A0A0; font-size: 8px;" align="left">За месяц</th><td></td></tr><tr><th style="color: #080A1C; font-size: 22px; font-weight: bold;" align="center">{valueY}</th></tr><tr><th style="color: #A0A0A0; font-size: 12px;" align="left">Сентябрь {day}</th></tr></table>';

  bullet.adapter.add("fill", function (fill, target) {
    if (target.dataItem.valueY < 0) {
      return am4core.color("#000");
    }
    return fill;
  });
  const range = valueAxis.createSeriesRange(series);
  range.value = 0;
  range.endValue = -1000;
  range.contents.stroke = am4core.color("#000");
  range.contents.fill = range.contents.stroke;

  // Add scrollbar
  // const scrollbarX = new am4charts.XYChartScrollbar()
  // scrollbarX.series.push(series)
  // chart.scrollbarX = scrollbarX

  chart.cursor = new am4charts.XYCursor();
  // chart.legend = new am4charts.Legend()
  series.fill = am4core.color("#32A8FE");
  series.stroke = am4core.color("#338DE0");
  series.strokeWidth = 4;
  chart.fontSize = 14;

  return (
    <div className="line-chart">
      <div className="chart"></div>
    </div>
  );
}
