import React, { Component } from "react";
import Registration from "./registration";
import './index.scss';
import RegistrationAgent from "./registrationAgent";
import { RegistrationInvestor } from "./registrationInvestor";
class RegisterMain extends Component {
  state = {
    activeTab: "userRegistration"
  };

  tabChange = e => {
    this.setState({
      activeTab: e
    });
  };

  render() {
    const { activeTab } = this.state;
    return (<div>
      <div className="registratinTabs defaultBg">
        <span className={activeTab === 'userRegistration' && 'activeRegTab'} onClick={() => this.tabChange("userRegistration")}>РЕГИСТРАЦИЯ</span>
        <span className={activeTab === 'agentRegistration' && 'activeRegTab'}  onClick={() => this.tabChange("agentRegistration")}>РЕГИСТРАЦИЯ КОНТРАГЕНТА</span>
        <span className={activeTab === 'investorRegistration' && 'activeRegTab'}  onClick={() => this.tabChange("investorRegistration")}>РЕГИСТРАЦИЯ ИНВЕСТОРА</span>
      </div>

      <div className={'defaultBg'}>
        {activeTab === "userRegistration" && <Registration role={this.props.role} updateList={this.props.updateList} parentProps={this.props}/>}
      </div>
      <div className={'defaultBg'}>
        {activeTab === "agentRegistration" && <RegistrationAgent role={this.props.role} updateList={this.props.updateList} parentProps={this.props}/>}
      </div>
      <div className={'defaultBg'}>
        {activeTab === "investorRegistration" && <RegistrationInvestor role={this.props.role} updateList={this.props.updateList} parentProps={this.props}/>}
      </div>
    </div>);
  }
}

export default RegisterMain;
