import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Slider,
  Upload,
  Checkbox,
  Switch,
  InputNumber,
  Table,
  Empty,
  // Space,
} from "antd";

import { ReactComponent as Close } from "../../assets/images/icons/close_modal.svg";
// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { finSource } from "../../global";
import CenteringMap from "../ProjectsMap/centeringMap";
import React, { Component, useState } from "react";

import {
  addFile,
  getMembers,
  postProjects,
  putProjects,
  getProject,
  BackendUrl,
  getProjects,
  deleteFile,
  getCompaniesList,
  getContractorUsers,
} from "../../actions/mainActions";
import moment from "moment";
import locale from "antd/es/date-picker/locale/ru_RU";
import DoughnutChart from "../Charts/DoughnutChart";
import Chart from "../Charts/Chart";
import FormItem from "antd/lib/form/FormItem";
import { render } from "@testing-library/react";
import "./myprojects.scss";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const budgetColors = [
  "#7459D9",
  "#7459D980",
  "#7459D94D",
  "#54a0ff",
  "#5f27cd",
];

class CreateEditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kuratorUsers: [],
      executorUsers: [],
      roleMember: "",
      selectedusersId: [],
      selectedUsers: [],
      avatarList: [],
      businessPlansList: [],
      finEconomList: [],
      marketingPlanList: [],
      filesList: [],
      fileZu: null,
      fileGip: null,
      fileFeo: null,
      filePsd: null,
      allMembers: [],
      projectTypes: [],
      tags: [],
      projects: [],
      coords: [],
      projectsList: [],
      loading: false,
      isProjectInfrastructure: false,
      isProjectInvestment: false,
      isProjectContract: false,
      finSourceSelected: [],
      selectContragentsModalIsActive: false,
      contragentTableColumns: [
        {
          title: "ФИО",
          dataIndex: "name",
        },
        {
          title: "Должность",
          dataIndex: "age",
        },
        {
          title: "Логин",
          dataIndex: "address",
        },
      ],
      contragentTableData: [],
      companiesData: [],

      // freeBudgetSum: 0
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { editId } = this.props;
    this.getAllMembers();
    this.getAllProjects();
    if (editId) {
      this.getProjectsDetail();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editId !== prevProps.editId) {
      this.getAllMembers();
      this.getAllProjects();
      if (this.props.editId) {
        this.getProjectsDetail();
      } else {
        this.setState({
          avatarList: [],
          filesList: [],
          coords: [],
        });
        this.formRef.current.resetFields();
      }
    }
    if (prevState.selectedUsers !== this.state.selectedUsers) {
      this.setState({ selectedUsers: this.state.selectedUsers });
    }
  }

  changeProjectTypeInvestment = (e) => {
    this.setState({
      isProjectInvestment: e.target.checked,
      isProjectInfrastructure: false,
      isProjectContract: false,
    });
  };

  changeProjectTypeInfrastructure = (e) => {
    this.setState({
      isProjectInfrastructure: e.target.checked,
      isProjectInvestment: false,
      isProjectContract: false,
    });
  };

  changeProjectTypeContract = (e) => {
    this.setState({
      isProjectContract: e.target.checked,
      isProjectInvestment: false,
      isProjectInfrastructure: false,
    });
  };

  handleOk = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      loading: true,
    });
    const objToSave = e;

    if (this.state.isProjectInfrastructure) {
      objToSave.project_type = "infrastructure";
    }

    if (this.state.isProjectInvestment) {
      objToSave.project_type = "investment";
    }

    if (this.state.isProjectContract) {
      objToSave.project_type = "contract";
    }
    objToSave.type = "project";
    objToSave.start = moment(e.projectDates[0]).format("YYYY-MM-DD");
    objToSave.end = moment(e.projectDates[1]).format("YYYY-MM-DD");
    objToSave.filesArr = [];
    objToSave.middle_point = JSON.stringify(this.state.coords);
    this.state.filesList.forEach((el) => {
      objToSave.filesArr.push(el.response);
    });
    if (this.state.avatarList.length > 0) {
      objToSave.avatar = this.state.avatarList[0].response.toString();
    }
    // return;
    postProjects(objToSave).then(
      (res) => {
        message.success("Проект успешно создан");
        this.setState({
          loading: false,
        });
        this.props.handleCancel();
        this.props.getListProjects();
      },
      (err) => {
        message.error("Ошибка при сохранении");
        this.setState({
          loading: false,
        });
      }
    );
  };
  handleEditSumbited = (e) => {
    this.formRef.current.resetFields();
    this.setState({
      loading: true,
    });
    const { editId } = this.props;
    const objToSave = e;
    objToSave.type = "project";
    objToSave.start = moment(e.projectDates[0]).format("YYYY-MM-DD");
    objToSave.end = moment(e.projectDates[1]).format("YYYY-MM-DD");
    objToSave.filesArr = [];
    objToSave.middle_point = JSON.stringify(this.state.coords);
    this.state.filesList.forEach((el) => {
      objToSave.filesArr.push(el.response);
    });

    objToSave.avatar = null;

    if (this.state.avatarList.length) {
      objToSave.avatar = this.state.avatarList[0].response;
    }

    if (this.state.isProjectInfrastructure) {
      objToSave.project_type = "infrastructure";
    } else

    if (this.state.isProjectInvestment) {
      objToSave.project_type = "investment";
    } else

    if (this.state.isProjectContract) {
      objToSave.project_type = "contract";
    } else objToSave.project_type = 'normal'

    objToSave.zu_file_id = this.state.fileZu ? this.state.fileZu.id : null;
    objToSave.gip_file_id = this.state.fileGip ? this.state.fileGip.id : null;
    objToSave.feo_file_id = this.state.fileFeo ? this.state.fileFeo.id : null;
    objToSave.psd_file_id = this.state.filePsd ? this.state.filePsd.id : null;

    objToSave.business_plan_file_id = this.state.businessPlansList.length
      ? this.state.businessPlansList[0].response
      : null;
    objToSave.fin_econom_file_id = this.state.finEconomList.length
      ? this.state.finEconomList[0].response
      : null;
    objToSave.marketing_plan_file_id = this.state.marketingPlanList.length
      ? this.state.marketingPlanList[0].response
      : null;
    putProjects(objToSave, editId).then(
      (res) => {
        message.success("Проект успешно обновлен");
        this.props.handleCancel();
        this.props.getListProjects();
        this.setState({
          loading: false,
        });
      },
      (err) => {
        message.error("Ошибка при сохранении");
        this.setState({
          loading: false,
        });
      }
    );
    console.log('data', e)
  };

  // tagChanged = (value) => {
  //     if (isNaN(value)) {
  //         console.log("Существующий тег");
  //     } else {
  //         console.log("Новый тег");
  //     }
  // };

  setNewCenterPoint = (coords) => {
    this.setState({
      coords,
    });
  };

  uploadAvatar = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      onSuccess(file);
      const avatarList = this.state.avatarList;
      avatarList.push({
        uid: file.uid,
        name: file.name,
        status: "done",
        response: res.data.data.ids[0],
        url: BackendUrl + res.data.data.src[0],
      });
      this.setState({
        avatarList,
      });
    });
  };

  uploadFile = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      const filesList = this.state.filesList;
      filesList.push({
        uid: file.uid,
        name: file.name,
        status: "done",
        response: res.data.data.ids[0],
        url: BackendUrl + res.data.data.src[0],
      });
      this.setState({
        filesList,
      });
    });
  };

  uploadFileZu = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      this.setState({
        fileZu: {
          uid: res.data.data.ids[0],
          id: res.data.data.ids[0],
          name: file.name,
          status: "done",
          url: BackendUrl + res.data.data.src[0],
        },
      });
    });
  };

  uploadFileGip = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      this.setState({
        fileGip: {
          uid: res.data.data.ids[0],
          id: res.data.data.ids[0],
          name: file.name,
          status: "done",
          url: BackendUrl + res.data.data.src[0],
        },
      });
    });
  };

  uploadFileFeo = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      this.setState({
        fileFeo: {
          uid: res.data.data.ids[0],
          id: res.data.data.ids[0],
          name: file.name,
          status: "done",
          url: BackendUrl + res.data.data.src[0],
        },
      });
    });
  };

  uploadFilePsd = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      this.setState({
        filePsd: {
          uid: res.data.data.ids[0],
          id: res.data.data.ids[0],
          name: file.name,
          status: "done",
          url: BackendUrl + res.data.data.src[0],
        },
      });
    });
  };

  getAllMembers = () => {
    getMembers().then((res) => {
      const allMembers = res.data.data;
      this.setState({
        allMembers,
      });
    });
  };

  getAllProjects = () => {
    const { editId } = this.props;
    getProjects().then((res) => {
      const mapped = res.data.data.map((p) => ({ id: p.id, name: p.name }));
      const projectsList = editId
        ? mapped.filter((el) => el.id !== editId)
        : mapped;
      this.setState({
        projectsList,
      });
    });
  };

  removeDocuments = (e) => {
    const filesList = [...this.state.filesList];
    const index = filesList.findIndex((el) => el.uid === e.uid);
    deleteFile(e.response).then((res) => {
      if (index > -1) {
        filesList.splice(index, 1);
      }
      this.setState({
        filesList,
      });
    });
  };

  removeAvatar = (e) => {
    const avatarList = [...this.state.avatarList];
    const index = avatarList.findIndex((el) => el.uid === e.uid);
    if (index > -1) {
      avatarList.splice(index, 1);
    }
    this.setState({
      avatarList,
    });
  };

  uploadBusinessPlan = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      onSuccess(file);
      const businessPlansList = this.state.businessPlansList;
      businessPlansList.push({
        uid: file.uid,
        name: file.name,
        status: "done",
        response: res.data.data.ids[0],
        url: BackendUrl + res.data.data.src[0],
      });
      this.setState({
        businessPlansList,
      });
    });
  };

  removeBusinessPlan = (e) => {
    const businessPlansList = [...this.state.businessPlansList];
    const index = businessPlansList.findIndex((el) => el.uid === e.uid);
    if (index > -1) {
      businessPlansList.splice(index, 1);
    }
    this.setState({
      businessPlansList,
    });
  };

  uploadFinEconom = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      onSuccess(file);
      const finEconomList = this.state.finEconomList;
      finEconomList.push({
        uid: file.uid,
        name: file.name,
        status: "done",
        response: res.data.data.ids[0],
        url: BackendUrl + res.data.data.src[0],
      });
      this.setState({
        finEconomList,
      });
    });
  };

  removeFinEconom = (e) => {
    const finEconomList = [...this.state.finEconomList];
    const index = finEconomList.findIndex((el) => el.uid === e.uid);
    if (index > -1) {
      finEconomList.splice(index, 1);
    }
    this.setState({
      finEconomList,
    });
  };

  uploadMarketingPlan = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      onSuccess(file);
      const marketingPlanList = this.state.marketingPlanList;
      marketingPlanList.push({
        uid: file.uid,
        name: file.name,
        status: "done",
        response: res.data.data.ids[0],
        url: BackendUrl + res.data.data.src[0],
      });
      this.setState({
        marketingPlanList,
      });
    });
  };

  removeMarketingPlan = (e) => {
    const marketingPlanList = [...this.state.marketingPlanList];
    const index = marketingPlanList.findIndex((el) => el.uid === e.uid);
    if (index > -1) {
      marketingPlanList.splice(index, 1);
    }
    this.setState({
      marketingPlanList,
    });
  };
  showSelectContragentsModal = (role) => {
    this.setState({
      selectContragentsModalIsActive: true,
      roleMember: role,
    });
  };

  hideSelectContragentsModal = () => {
    this.setState({
      selectContragentsModalIsActive: false,
    });
  };

  getProjectsDetail = () => {
    const { editId } = this.props;
    getProject(editId).then((res) => {
      const projectValues = res.data.data;
      const avatar = res.data.data.avatar;
      const filesList =
        res.data.data.files.length > 0
          ? res.data.data.files.map((el) => {
              return {
                uid: el.id,
                response: el.id,
                name: el.name,
                status: "done",
                url: BackendUrl + el.src,
              };
            })
          : [];

      // const fileZu = {
      //     uid: el.id,
      //     response: el.id,
      //     name: el.name,
      //     status: "done",
      //     url: BackendUrl + el.src,
      // };

      let isProjectInfrastructure = false;
      if (projectValues.project_type === "infrastructure") {
        isProjectInfrastructure = true;
      }
      let isProjectInvestment = false;
      if (projectValues.project_type === "investment") {
        isProjectInvestment = true;
      }
      projectValues.projectDates = [
        moment(res.data.data.start),
        moment(res.data.data.end),
      ];
      const managers = Object.assign([], projectValues.managers)
      const members = Object.assign([], projectValues.members)
      const curators = Object.assign([], projectValues.curators)

      projectValues.managers = res.data.data.managers.map((el) => el.id);
      projectValues.members = res.data.data.members.map((el) => el.id);
      projectValues.curators = res.data.data.curators.map((el) => el.id);

      // this.setState({freeBudgetSum: res.data.data.fin_sum})

      if (projectValues.budget_date) {
        projectValues.budget_date = moment(projectValues.budget_date);
      }

      if (projectValues.dkz_date) {
        projectValues.dkz_date = moment(projectValues.dkz_date);
      }

      if (projectValues.feo_date) {
        projectValues.feo_date = moment(projectValues.feo_date);
      }

      if (projectValues.gip_date) {
        projectValues.gip_date = moment(projectValues.gip_date);
      }

      if (projectValues.gprt_date) {
        projectValues.gprt_date = moment(projectValues.gprt_date);
      }

      if (projectValues.psd_date) {
        projectValues.psd_date = moment(projectValues.psd_date);
      }

      if (projectValues.teo_date) {
        projectValues.teo_date = moment(projectValues.teo_date);
      }

      if (projectValues.zu_date) {
        projectValues.zu_date = moment(projectValues.zu_date);
      }

      if (projectValues.zu_file) {
        projectValues.zu_file.uid = projectValues.zu_file.id;
      }

      if (projectValues.gip_file) {
        projectValues.gip_file.uid = projectValues.gip_file.id;
      }

      if (projectValues.feo_file) {
        projectValues.feo_file.uid = projectValues.feo_file.id;
      }

      if (projectValues.psd_file) {
        projectValues.psd_file.uid = projectValues.psd_file.id;
      }

      if (!Array.isArray(projectValues.fin_source)) {
        projectValues.fin_source = [projectValues.fin_source];
      }

      this.setState({
        avatarList: avatar
          ? [
              {
                uid: avatar,
                response: avatar,
                name: avatar,
                status: "done",
                url: BackendUrl + res.data.data.avatar_src,
              },
            ]
          : [],
        fileZu: projectValues.zu_file,
        fileGip: projectValues.gip_file,
        fileFeo: projectValues.feo_file,
        filePsd: projectValues.psd_file,
        finSourceSelected: projectValues.fin_source,
        isProjectInfrastructure: isProjectInfrastructure,
        isProjectInvestment: isProjectInvestment,
        filesList,
        // fileZu,
        coords: JSON.parse(res.data.data.middle_point),
        selectedUsers: managers.map((e) => ({
          key: e.id,
          name: e.first_name + " " + e.last_name,
          login: e.email,
          role: e.role,
        })),
        kuratorUsers: curators.map((e) => ({
          key: e.id,
          name: e.first_name + " " + e.last_name,
          login: e.email,
          role: e.role,
        })),
        executorUsers: members.map((e) => ({
          key: e.id,
          name: e.first_name + " " + e.last_name,
          login: e.email,
          role: e.role,
        })),
      });
      this.formRef.current.setFieldsValue(projectValues);
    });
  };

  getFieldValue(name) {
    return this.formRef.current && this.formRef.current.getFieldValue(name);
  }

  setFieldsValue(name, value) {
    this.formRef.current &&
      this.formRef.current.setFieldsValue({ [name]: value });
  }
  getContractorUsersData = (selectedUsers) => {
    switch (this.state.roleMember) {
      case "kurator":
        this.setState({
          kuratorUsers: [...this.state.kuratorUsers, ...selectedUsers.filter(item => this.state.kuratorUsers.filter(el => (el.key ?? el.id) === item.key).length === 0)],
        });
        this.setFieldsValue(
          "curators",
          this.state.kuratorUsers.map((e) => e.key ? e.key : e.id)
        );
        break;
      case "executor":
        this.setState({
          executorUsers: [...this.state.executorUsers, ...selectedUsers.filter(item => this.state.executorUsers.filter(el => (el.key ?? el.id) === item.key).length === 0)],
        });
        this.setFieldsValue(
          "members",
          this.state.executorUsers.map((e) => e.key ? e.key : e.id)
        );
        break;
      case "rukovod":
        this.setState({
          selectedUsers: [...this.state.selectedUsers, ...selectedUsers.filter(item => this.state.selectedUsers.filter(el => (el.key ?? el.id) === item.key).length === 0)],
          selectedusersId: selectedUsers.map((e) => e.key),
        });
        
        this.setFieldsValue(
          "managers",
          this.state.selectedUsers.map((e) => e.key ? e.key : e.id)
        );
        break;
      default:
        return;
    }
  };

  render() {
    const { visible, handleCancel, editId } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        title={editId ? "Редактирование проекта" : "Добавить проект"}
        centered
        footer={null}
        width={"100%"}
        visible={visible}
        onOk={handleCancel}
        onCancel={handleCancel}
        closeIcon={<Close style={{ marginTop: "20px" }} />}
        className="my-ant-modal"
      >
        {visible && (
          <Form
            ref={this.formRef}
            name="basic"
            initialValues={this.state.initialValues}
            onFinish={!editId ? this.handleOk : this.handleEditSumbited}
            onFinishFailed={this.onFinishFailed}
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
          >
            <Col style={{ padding: "30px" }}>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Название</h3>
                <div>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Введите имя проекта",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Введите название проекта"
                      className="my-ant-input"
                    />
                  </Form.Item>
                </div>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Изображение</h3>
                <Form.Item name="avatar">
                  <Upload
                    onRemove={this.removeAvatar}
                    listType="picture"
                    fileList={this.state.avatarList}
                    customRequest={this.uploadAvatar}
                  >
                    {this.state.avatarList.length < 1 && (
                      <Button className={"pl-0"} size="large" type={"link"}>
                        + Загрузить изображение
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Описание</h3>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Введите описание проекта",
                    },
                  ]}
                >
                  <TextArea
                    rows={3}
                    placeholder="Введите описание проекта"
                    className="my-ant-input"
                  />
                </Form.Item>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Файл</h3>
                <Form.Item>
                  <Upload
                    onRemove={this.removeDocuments}
                    listType="text"
                    fileList={this.state.filesList}
                    customRequest={this.uploadFile}
                  >
                    {this.state.filesList.length >= 8 ? null : (
                      <Button className={"pl-0"} size="large" type={"link"}>
                        + Загрузить файл
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Руководитель</h3>
                <Button
                  className={"pl-0"}
                  size="large"
                  type={"link"}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    this.showSelectContragentsModal("rukovod");
                  }}
                >
                  + Добавить руководителей
                </Button>

                <Form.Item
                  name="managers"
                  style={{ opacity: this.state.selectedUsers ? "1" : "0" }}
                >
                  <Select
                    placeholder="Выберите Исполнителя"
                    id="select-manager"
                    size="large"
                    style={{ width: "100%" }}
                    mode="multiple"
                    showAction={["focus"]}
                    optionFilterProp="children"
                    defaultValue={
                      this.state.selectedUsers &&
                      this.state.selectedUsers.map((e) => e.name)
                    }
                    value={this.state.selectedusersId}
                    allowClear
                    className="my-ant-input-select"
                  >
                    {this.state.selectedUsers &&
                      this.state.selectedUsers.map((item) => (
                        <Option value={item.key} key={item.key}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                {/* <Button
                                        className={"pl-0"}
                                        size="large"
                                        type={"link"}
                                        onClick={() => this.showSelectContragentsModal()}
                                    >
                                        + Выбрать руководителя
                                    </Button> */}
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Куратор</h3>
                <Button
                  className={"pl-0"}
                  size="large"
                  type={"link"}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    this.showSelectContragentsModal("kurator");
                  }}
                >
                  + Добавить кураторов
                </Button>
                <Form.Item
                  name="curators"
                  rules={[
                    {
                      required: true,
                      message: "Выберите куратора",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Выберите куратора"
                    size="large"
                    style={{ width: "100%" }}
                    mode="multiple"
                    optionFilterProp="children"
                    className="my-ant-input-select"
                  >
                    {this.state.kuratorUsers &&
                      this.state.kuratorUsers.map((item) => (
                        <Option value={item.key} key={item.key}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Исполнители</h3>
                <Button
                  className={"pl-0"}
                  size="large"
                  type={"link"}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    this.showSelectContragentsModal("executor");
                  }}
                >
                  + Добавить исполнителей
                </Button>
                <Form.Item
                  name="members"
                  rules={[
                    {
                      required: true,
                      message: "Выберите исполнителя",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    mode="multiple"
                    id="select-member"
                    placeholder="Выберите исполнителей"
                    size="large"
                    style={{ width: "100%" }}
                    showAction={["focus"]}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .join(" ")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="my-ant-input-select"
                  >
                    {this.state.executorUsers &&
                      this.state.executorUsers.map((item) => (
                        <Option value={item.key} key={item.key}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Срок проекта</h3>
                <Form.Item
                  name="projectDates"
                  rules={[
                    {
                      required: true,
                      message: "Укажите сроки проекта",
                    },
                  ]}
                >
                  <RangePicker
                    style={{ width: "100%" }}
                    locale={locale}
                    size="large"
                    format="DD.MM.YYYY"
                    placeholder={["С", "По"]}
                    className="my-ant-input-datepicker"
                  />
                </Form.Item>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Сделать подпроектом</h3>
                <Form.Item name="parent_id">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Выберите проект"
                    size="large"
                    allowClear
                    style={{ width: "100%" }}
                    // mode="multiple"
                    className="my-ant-input-select"
                  >
                    {this.state.projectsList.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Сумма</h3>
                <div style={{ position: "relative" }}>
                  <Form.Item name="fin_sum">
                    <InputNumber
                      // type="number"
                      // defaultValue={1000}
                      formatter={(value) =>
                        `${value} ₸`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      }
                      parser={(value) => value.replace(/(\s*)\s*₸*/g, "")}
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Введите сумму"
                      className="my-ant-input-number"
                    />
                    {/* <div style={{position: 'absolute', right: '30px', top: '7px', fontSize: '16px' }}>тенге</div> */}
                  </Form.Item>
                </div>
              </Row>
              <Row style={{ marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    borderRadius: "8px",
                    background: "#ccc",
                    width: "100%",
                    height: 30,
                    overflow: "hidden",
                  }}
                >
                  {this.state.finSourceSelected.map((item, index) => (
                    <div
                      style={{
                        background: budgetColors[index],
                        height: 40,
                        width:
                          ((this.getFieldValue("fin_source_sum_" + item) ?? 0) *
                            100) /
                            this.getFieldValue("fin_sum") +
                          "%",
                        display: "flex",
                        alignItems: "center",
                        color: "#fff",
                        fontWeight: "bold",
                        overflow: "hidden",
                      }}
                      key={index}
                    >
                      <span style={{ paddingLeft: "10px" }}>
                        {((this.getFieldValue("fin_source_sum_" + item) ?? 0) *
                          100) /
                          this.getFieldValue("fin_sum") >
                        8
                          ? (
                              ((this.getFieldValue("fin_source_sum_" + item) ??
                                0) *
                                100) /
                              this.getFieldValue("fin_sum")
                            ).toFixed(2) + "%"
                          : null}
                      </span>
                    </div>
                  ))}
                  {/* <div style={{background: '#FF7B7B', height: 40, width: '10%'}}></div>
                                    <div style={{background: '#FFBA7B', height: 40, width: '20%'}}></div>
                                    <div style={{background: '#1dd1a1', height: 40, width: '30%'}}></div>
                                    <div style={{background: '#54a0ff', height: 40, width: '25%'}}></div>
                                    <div style={{background: '#5f27cd', height: 40, width: '25%'}}></div> */}
                </div>
              </Row>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Источники финансирования</h3>
                <Form.Item name="fin_source">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Выберите источник"
                    size="large"
                    style={{ width: "100%" }}
                    mode="multiple"
                    optionFilterProp="children"
                    onChange={(value) => {
                      this.setState({ finSourceSelected: value });
                    }}
                    // filterOption={(input, option) =>
                    //     option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    className="my-ant-input-select"
                  >
                    {finSource.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
              {this.state.finSourceSelected.map((item) => (
                <Row className="my-ant-row-grid">
                  <h3 className="row-title-3">
                    Сумма "{finSource.find((e) => e.value === item).label}"
                  </h3>
                  <div style={{ position: "relative" }}>
                    <Form.Item name={"fin_source_sum_" + item}>
                      <InputNumber
                        // type="number"
                        defaultValue={"0"}
                        formatter={(value) =>
                          `${value} ₸`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        }
                        parser={(value) => value.replace(/(\s*)\s*₸*/g, "")}
                        size="large"
                        onChange={(value) => {
                          let freeBudgetSum = this.getFieldValue("fin_sum");

                          for (let finSourceItem of this.state
                            .finSourceSelected) {
                            if (finSourceItem === item) {
                              continue;
                            }

                            freeBudgetSum -=
                              this.getFieldValue(
                                "fin_source_sum_" + finSourceItem
                              ) ?? 0;
                          }

                          if (value > freeBudgetSum) {
                            this.setFieldsValue(
                              "fin_source_sum_" + item,
                              freeBudgetSum
                            );
                          }

                          this.setState({});
                        }}
                        style={{ width: "100%" }}
                        className="my-ant-input-number"
                        placeholder="Введите сумму"
                      />
                      {/* <div style={{position: 'absolute', right: '30px', top: '7px', fontSize: '16px' }}>тенге</div> */}
                    </Form.Item>
                  </div>
                </Row>
              ))}

              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Процент выполнения</h3>
                <Form.Item name="perform_percent">
                  <Slider />
                </Form.Item>
              </Row>
              {/* <Row gutter={12}>
                <Col span={24}>
                  <CenteringMap
                    setNewCenterPoint={this.setNewCenterPoint}
                    coords={this.state.coords}
                  />
                </Col>
              </Row> */}
            </Col>

            <Col style={{ padding: "30px", background: "#F2F7FF" }}>
              <Row className="my-ant-row-grid">
                <h3 className="row-title-3">Тип проекта</h3>
                <Row style={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
                  <Form.Item name="project_type">
                    <Checkbox
                      checked={this.state.isProjectInfrastructure}
                      onChange={this.changeProjectTypeInfrastructure}
                    >
                      Инфраструктурный
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="project_type">
                    <Checkbox
                      checked={this.state.isProjectInvestment}
                      onChange={this.changeProjectTypeInvestment}
                    >
                      Инвестиционный
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="project_type">
                    <Checkbox
                      checked={this.state.isProjectContract}
                      onChange={this.changeProjectTypeContract}
                    >
                      Договор
                    </Checkbox>
                  </Form.Item>
                </Row>
              </Row>
              {this.state.isProjectInfrastructure ? (
                <>
                  <Row className="project-type-grid">
                    <h3 className="row-title-3">ГПРТ</h3>
                    <Form.Item name="gprt" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="gprt_comment">
                        <TextArea
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("gprt") ? (
                        <Row className="project-type-element">
                          <Col className="project-type-element-item">
                            <h3 className="row-title-3">Номер</h3>
                            <Form.Item name="gprt_number">
                              <Input className="my-ant-input-number" />
                            </Form.Item>
                          </Col>
                          <Col className="project-type-element-item">
                            <h3 className="row-title-3">Дата</h3>
                            <Form.Item name="gprt_date">
                              <DatePicker
                                defaultValue={moment()}
                                format="DD.MM.YYYY"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">ДКЗ</h3>
                    <Form.Item name="dkz" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="dkz_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("dkz")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("dkz") ? (
                        <Row className="project-type-element">
                          <Col className="project-type-element-item">
                            <h3 className="row-title-3">Номер</h3>
                            <Form.Item name="dkz_number">
                              <Input className="my-ant-input-number" />
                            </Form.Item>
                          </Col>
                          <Col className="project-type-element-item">
                            <h3 className="row-title-3">Дата</h3>
                            <Form.Item name="dkz_date">
                              <DatePicker
                                defaultValue={moment()}
                                format="DD.MM.YYYY"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">ТЭО</h3>
                    <Form.Item name="teo" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="teo_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("teo")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("teo") ? (
                        <Row className="project-type-element">
                          <Col className="project-type-element-item">
                            <h3 className="row-title-3">Номер</h3>
                            <Form.Item name="teo_number">
                              <Input className="my-ant-input-number" />
                            </Form.Item>
                          </Col>
                          <Col className="project-type-element-item">
                            <h3 className="row-title-3">Дата</h3>
                            <Form.Item name="teo_date">
                              <DatePicker
                                defaultValue={moment()}
                                format="DD.MM.YYYY"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">ПСД</h3>
                    <Form.Item name="psd" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="psd_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("psd")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("psd") ? (
                        <Row className="project-type-element">
                          <Col className="project-type-element-item">
                            <h3 className="row-title-3">Номер</h3>
                            <Form.Item name="psd_number">
                              <Input className="my-ant-input-number" />
                            </Form.Item>
                          </Col>
                          <Col className="project-type-element-item">
                            <h3 className="row-title-3">Дата</h3>
                            <Form.Item name="psd_date">
                              <DatePicker
                                defaultValue={moment()}
                                format="DD.MM.YYYY"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">Бюджетная заявка</h3>
                    <Form.Item name="budget">
                      <label>
                        <Button
                          onClick={() => {
                            const val = this.getFieldValue("budget");

                            if (val === undefined || val === false) {
                              this.formRef.current.setFieldsValue({
                                budget: true,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                budget: undefined,
                              });
                            }
                            this.setState({});
                          }}
                          style={{
                            background:
                              typeof this.getFieldValue("budget") ===
                                "boolean" && this.getFieldValue("budget")
                                ? "#38B836"
                                : "#C6D1DD",
                            borderColor:
                              typeof this.getFieldValue("budget") ===
                                "boolean" && this.getFieldValue("budget")
                                ? "#38B836"
                                : "#C6D1DD",
                            color: "#fff",
                            marginRight: "20px",
                          }}
                        >
                          Да{" "}
                        </Button>
                        <Button
                          onClick={() => {
                            const val = this.getFieldValue("budget");

                            if (val === undefined || val === true) {
                              this.formRef.current.setFieldsValue({
                                budget: false,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                budget: undefined,
                              });
                            }
                            this.setState({});
                          }}
                          style={{
                            background:
                              typeof this.getFieldValue("budget") ===
                                "boolean" && !this.getFieldValue("budget")
                                ? "#FF7B7B"
                                : "#C6D1DD",
                            borderColor:
                              typeof this.getFieldValue("budget") ===
                                "boolean" && !this.getFieldValue("budget")
                                ? "#FF7B7B"
                                : "#C6D1DD",
                            color: "#fff",
                          }}
                        >
                          Нет{" "}
                        </Button>
                      </label>
                    </Form.Item>
                    <Form.Item
                      name="budget_comment"
                      className="project-type-element-items"
                    >
                      <TextArea
                        // disabled={!this.getFieldValue("budget")}
                        rows={2}
                        placeholder="Комментарий"
                        className="my-ant-input"
                      />
                    </Form.Item>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">Статус БЗ</h3>
                    <Form.Item name="budget_status">
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder="Выберите статус"
                        size="large"
                        allowClear
                        onChange={() => this.setState({})}
                        className="my-ant-input-select"
                        // mode="multiple"
                      >
                        <Option value="approved">Одобрена</Option>
                        <Option value="disabled">Отказана</Option>
                        <Option value="revision">На рассмотрении</Option>
                        <Option value="partial">Частично</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="budget_status_comment"
                      className="project-type-element-items"
                    >
                      <TextArea
                        // disabled={!this.getFieldValue("budget_status")}
                        rows={2}
                        placeholder="Комментарий"
                        className="my-ant-input"
                      />
                    </Form.Item>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">Конкурсные процедуры</h3>
                    <Form.Item name="competitive" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Form.Item
                      name="competitive_comment"
                      className="project-type-element-items"
                    >
                      <TextArea
                        // disabled={!this.getFieldValue("competitive")}
                        rows={2}
                        placeholder="Комментарий"
                        className="my-ant-input"
                      />
                    </Form.Item>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">СМР</h3>
                    <Form.Item name="smr" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Form.Item
                      name="smr_comment"
                      className="project-type-element-items"
                    >
                      <TextArea
                        // disabled={!this.getFieldValue("smr")}
                        rows={2}
                        placeholder="Комментарий"
                        className="my-ant-input"
                      />
                    </Form.Item>
                  </Row>
                </>
              ) : null}

              {this.state.isProjectInvestment ? (
                <>
                  <Row className="project-type-grid">
                    <h3 className="row-title-3">ЗУ</h3>
                    <Form.Item name="zu" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="zu_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("zu")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("zu") ? (
                        <>
                          <Row className="project-type-element">
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Номер</h3>
                              <Form.Item name="zu_number">
                                <Input className="my-ant-input-number" />
                              </Form.Item>
                            </Col>
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Дата</h3>
                              <Form.Item name="zu_date">
                                <DatePicker
                                  defaultValue={moment()}
                                  format="DD.MM.YYYY"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="project-type-element">
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Файл</h3>
                              <Upload
                                onRemove={this.removeDocuments}
                                listType="text"
                                fileList={
                                  this.state.fileZu ? [this.state.fileZu] : []
                                }
                                customRequest={this.uploadFileZu}
                              >
                                {this.state.fileZu ? null : (
                                  <Button
                                    className={"pl-0"}
                                    size="large"
                                    type={"link"}
                                  >
                                    + Загрузить файл
                                  </Button>
                                )}
                              </Upload>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">ГИП</h3>
                    <Form.Item name="gip" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="gip_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("gip")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("gip") ? (
                        <>
                          <Row className="project-type-element">
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Номер</h3>
                              <Form.Item name="gip_number">
                                <Input className="my-ant-input-number" />
                              </Form.Item>
                            </Col>
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Дата</h3>
                              <Form.Item name="gip_date">
                                <DatePicker
                                  defaultValue={moment()}
                                  format="DD.MM.YYYY"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="project-type-element">
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Файл</h3>
                              <Upload
                                onRemove={this.removeDocuments}
                                listType="text"
                                fileList={
                                  this.state.fileGip ? [this.state.fileGip] : []
                                }
                                customRequest={this.uploadFileGip}
                              >
                                {this.state.fileGip ? null : (
                                  <Button
                                    className={"pl-0"}
                                    size="large"
                                    type={"link"}
                                  >
                                    + Загрузить файл
                                  </Button>
                                )}
                              </Upload>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">ФЭО</h3>
                    <Form.Item name="feo" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="feo_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("feo")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("feo") ? (
                        <>
                          <Row className="project-type-element">
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Номер</h3>
                              <Form.Item name="feo_number">
                                <Input className="my-ant-input-number" />
                              </Form.Item>
                            </Col>
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Дата</h3>
                              <Form.Item name="feo_date">
                                <DatePicker defaultValue={moment()} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="project-type-element">
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Файл</h3>
                              <Upload
                                onRemove={this.removeDocuments}
                                listType="text"
                                fileList={
                                  this.state.fileFeo ? [this.state.fileFeo] : []
                                }
                                customRequest={this.uploadFileFeo}
                              >
                                {this.state.fileFeo ? null : (
                                  <Button
                                    className={"pl-0"}
                                    size="large"
                                    type={"link"}
                                  >
                                    + Загрузить файл
                                  </Button>
                                )}
                              </Upload>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">ПСД</h3>
                    <Form.Item name="psd" valuePropName="checked">
                      <Switch onChange={() => this.setState({})} />
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="psd_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("psd")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("psd") ? (
                        <>
                          <Row className="project-type-element">
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Номер</h3>
                              <Form.Item name="psd_number">
                                <Input className="my-ant-input-number" />
                              </Form.Item>
                            </Col>
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Дата</h3>
                              <Form.Item name="psd_date">
                                <DatePicker defaultValue={moment()} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="project-type-element">
                            <Col className="project-type-element-item">
                              <h3 className="row-title-3">Файл</h3>
                              <Upload
                                onRemove={this.removeDocuments}
                                listType="text"
                                fileList={
                                  this.state.filePsd ? [this.state.filePsd] : []
                                }
                                customRequest={this.uploadFilePsd}
                              >
                                {this.state.filePsd ? null : (
                                  <Button
                                    className={"pl-0"}
                                    size="large"
                                    type={"link"}
                                  >
                                    + Загрузить файл
                                  </Button>
                                )}
                              </Upload>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">Бюджетная заявка</h3>
                    <Form.Item name="business_plan">
                      <label>
                        <Button
                          onClick={() => {
                            const val = this.getFieldValue("business_plan");

                            if (val === undefined || val === false) {
                              this.formRef.current.setFieldsValue({
                                business_plan: true,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                business_plan: undefined,
                              });
                            }
                            this.setState({});
                          }}
                          style={{
                            background:
                              typeof this.getFieldValue("business_plan") ===
                                "boolean" && this.getFieldValue("business_plan")
                                ? "#38B836"
                                : "#C6D1DD",
                            borderColor:
                              typeof this.getFieldValue("business_plan") ===
                                "boolean" && this.getFieldValue("business_plan")
                                ? "#38B836"
                                : "#C6D1DD",
                            color: "#fff",
                            marginRight: "20px",
                          }}
                        >
                          Да{" "}
                        </Button>
                        <Button
                          onClick={() => {
                            const val = this.getFieldValue("business_plan");

                            if (val === undefined || val === true) {
                              this.formRef.current.setFieldsValue({
                                business_plan: false,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                business_plan: undefined,
                              });
                            }
                            this.setState({});
                          }}
                          style={{
                            background:
                              typeof this.getFieldValue("business_plan") ===
                                "boolean" &&
                              !this.getFieldValue("business_plan")
                                ? "#FF7B7B"
                                : "#C6D1DD",
                            borderColor:
                              typeof this.getFieldValue("business_plan") ===
                                "boolean" &&
                              !this.getFieldValue("business_plan")
                                ? "#FF7B7B"
                                : "#C6D1DD",
                            color: "#fff",
                          }}
                        >
                          Нет{" "}
                        </Button>
                      </label>
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="business_plan_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("business_plan")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("business_plan") ? (
                        <Row className="project-type-element">
                          <Col className="project-type-element-item">
                            <Upload
                              onRemove={this.removeBusinessPlan}
                              listType="text"
                              fileList={this.state.businessPlansList}
                              customRequest={this.uploadBusinessPlan}
                            >
                              {this.state.businessPlansList.length >=
                              1 ? null : (
                                <Button
                                  className={"pl-0"}
                                  size="large"
                                  type={"link"}
                                >
                                  + Прикрепить файл
                                </Button>
                              )}
                            </Upload>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">Фин эконом модель</h3>
                    <Form.Item name="fin_econom">
                      <label>
                        <Button
                          onClick={() => {
                            const val = this.getFieldValue("fin_econom");

                            if (val === undefined || val === false) {
                              this.formRef.current.setFieldsValue({
                                fin_econom: true,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                fin_econom: undefined,
                              });
                            }
                            this.setState({});
                          }}
                          style={{
                            background:
                              typeof this.getFieldValue("fin_econom") ===
                                "boolean" && this.getFieldValue("fin_econom")
                                ? "#38B836"
                                : "#C6D1DD",
                            borderColor:
                              typeof this.getFieldValue("fin_econom") ===
                                "boolean" && this.getFieldValue("fin_econom")
                                ? "#38B836"
                                : "#C6D1DD",
                            color: "#fff",
                            marginRight: "20px",
                          }}
                        >
                          Да{" "}
                        </Button>
                        <Button
                          onClick={() => {
                            const val = this.getFieldValue("fin_econom");

                            if (val === undefined || val === true) {
                              this.formRef.current.setFieldsValue({
                                fin_econom: false,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                fin_econom: undefined,
                              });
                            }
                            this.setState({});
                          }}
                          style={{
                            background:
                              typeof this.getFieldValue("fin_econom") ===
                                "boolean" && !this.getFieldValue("fin_econom")
                                ? "#FF7B7B"
                                : "#C6D1DD",
                            borderColor:
                              typeof this.getFieldValue("fin_econom") ===
                                "boolean" && !this.getFieldValue("fin_econom")
                                ? "#FF7B7B"
                                : "#C6D1DD",
                            color: "#fff",
                          }}
                        >
                          Нет{" "}
                        </Button>
                      </label>
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="fin_econom_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("fin_econom")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("fin_econom") ? (
                        <Row className="project-type-element">
                          <Col className="project-type-element-item">
                            <Upload
                              onRemove={this.removeFinEconom}
                              listType="text"
                              fileList={this.state.finEconomList}
                              customRequest={this.uploadFinEconom}
                            >
                              {this.state.finEconomList.length >= 1 ? null : (
                                <Button
                                  className={"pl-0"}
                                  size="large"
                                  type={"link"}
                                >
                                  + Прикрепить файл
                                </Button>
                              )}
                            </Upload>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">Маркетинг План</h3>
                    <Form.Item name="marketing_plan">
                      <label>
                        <Button
                          onClick={() => {
                            const val = this.getFieldValue("marketing_plan");

                            if (val === undefined || val === false) {
                              this.formRef.current.setFieldsValue({
                                marketing_plan: true,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                marketing_plan: undefined,
                              });
                            }
                            this.setState({});
                          }}
                          style={{
                            background:
                              typeof this.getFieldValue("marketing_plan") ===
                                "boolean" &&
                              this.getFieldValue("marketing_plan")
                                ? "#38B836"
                                : "#C6D1DD",
                            borderColor:
                              typeof this.getFieldValue("marketing_plan") ===
                                "boolean" &&
                              this.getFieldValue("marketing_plan")
                                ? "#38B836"
                                : "#C6D1DD",
                            color: "#fff",
                            marginRight: "20px",
                          }}
                        >
                          Да{" "}
                        </Button>
                        <Button
                          onClick={() => {
                            const val = this.getFieldValue("marketing_plan");

                            if (val === undefined || val === true) {
                              this.formRef.current.setFieldsValue({
                                marketing_plan: false,
                              });
                            } else {
                              this.formRef.current.setFieldsValue({
                                marketing_plan: undefined,
                              });
                            }
                            this.setState({});
                          }}
                          style={{
                            background:
                              typeof this.getFieldValue("marketing_plan") ===
                                "boolean" &&
                              !this.getFieldValue("marketing_plan")
                                ? "#FF7B7B"
                                : "#C6D1DD",
                            borderColor:
                              typeof this.getFieldValue("marketing_plan") ===
                                "boolean" &&
                              !this.getFieldValue("marketing_plan")
                                ? "#FF7B7B"
                                : "#C6D1DD",
                            color: "#fff",
                          }}
                        >
                          Нет{" "}
                        </Button>
                      </label>
                    </Form.Item>
                    <Col className="project-type-element-items">
                      <Form.Item name="marketing_plan_comment">
                        <TextArea
                          // disabled={!this.getFieldValue("marketing_plan")}
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                      {this.getFieldValue("marketing_plan") ? (
                        <Row className="project-type-element">
                          <Col className="project-type-element-item">
                            <Upload
                              onRemove={this.removeMarketingPlan}
                              listType="text"
                              fileList={this.state.marketingPlanList}
                              customRequest={this.uploadMarketingPlan}
                            >
                              {this.state.marketingPlanList.length >=
                              1 ? null : (
                                <Button
                                  className={"pl-0"}
                                  size="large"
                                  type={"link"}
                                >
                                  + Прикрепить файл
                                </Button>
                              )}
                            </Upload>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="project-type-grid">
                    <h3 className="row-title-3">Текущее состояние</h3>
                    <Col className="project-type-element-items">
                      <Form.Item name="current_status">
                        <TextArea
                          rows={2}
                          placeholder="Комментарий"
                          className="my-ant-input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Col>

            <Row
              style={{
                padding: "30px",
                display: "flex",
                gap: "40px",
                alignItems: "center",
              }}
            >
              <Form.Item>
                {!this.state.edit && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{
                      width: "100%",
                      background: "#338DE0",
                      borderRadius: "8px",
                    }}
                    loading={loading}
                  >
                    {editId ? "Сохранить" : "Добавить проект"}
                  </Button>
                )}
                {this.state.edit && (
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{ width: "100%" }}
                  >
                    Обновить проект
                  </Button>
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleCancel();
                  }}
                  type="text"
                  size="large"
                  style={{ color: "#898989", textDecoration: "underline" }}
                >
                  ОТМЕНА
                </Button>
              </Form.Item>
            </Row>
          </Form>
        )}{" "}
          <ModalContragent
            selectContragentsModalIsActive={
              this.state.selectContragentsModalIsActive
            }
            showSelectContragentsModal={this.showSelectContragentsModal}
            hideSelectContragentsModal={this.hideSelectContragentsModal}
            companiesData={this.state.companiesData}
            getContractorUsersData={this.getContractorUsersData}
          />
      </Modal>
    );
  }
}

export default CreateEditProject;

export class ModalContragent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companiesData: [],
      search: null,
      users: [],
      selectedRows: [],
      pagination_total: 0,
      condactorQuery: '',
      tableLoading: false
    };
    this.contragentRefForm = React.createRef();
  }
  handleOk = (e) => {
    this.props.getContractorUsersData(this.state.selectedRows);
    this.props.hideSelectContragentsModal();
  };
  onFinishFailed = (e) => {};

  getFieldValue(name) {
    return this.formRef.current && this.formRef.current.getFieldValue(name);
  }

  setFieldsValue(name, value) {
    this.formRef.current &&
      this.formRef.current.setFieldsValue({ [name]: value });
  }
  componentDidMount() {
    getCompaniesList().then((response) => {
      this.setState({ companiesData: response.data.data.data });
    });
  }

  onSearch = (e) => {
    this.setState({ search: e.target.value });
  };
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedRows });
    },
  };
  condactorOnChange = (id) => {
    const condactorQuery = [];
    id.forEach((condactorId, idx) => {
      if (id.length > ++idx) {
        condactorQuery.push(`organization_ids[]=${condactorId}&`);
      } else {
        condactorQuery.push(`organization_ids[]=${condactorId}`);
      }
    });
    this.setState({
      tableLoading: true
    })
    if (condactorQuery.length > 0) {
      getContractorUsers(condactorQuery.join("")).then(
        (res) => {
          const data = res.data.data.users.map((e, idx) => ({
            key: e.id,
            name: e.first_name + " " + e.last_name,
            login: e.email,
            role: e.role,
          }));
          this.setState({ users: data, pagination_total: res.data.data.total, condactorQuery: condactorQuery.join(""), tableLoading: false });
        }
      );
    } else {
      this.setState({ users: [], pagination_total: 0, tableLoading: false });
    }
  };

  getContractorsPaginate = (condactorQuery, page) => {
    this.setState({
      tableLoading: true
    })
    getContractorUsers(condactorQuery, page).then(
      (res) => {
        const data = res.data.data.users.map((e, idx) => ({
          key: e.id,
          name: e.first_name + " " + e.last_name,
          login: e.email,
          role: e.role,
        }));
        this.setState({ users: data, pagination_total: res.data.data.total, tableLoading: false });
      }
    );
  }
  render() {
    const CheckboxGroup = Checkbox.Group;
    const columns = [
      {
        title: "ФИО",
        dataIndex: "name",
      },
      {
        title: "Должность",
        dataIndex: "role",
      },
      {
        title: "Логин",
        dataIndex: "login",
      },
    ];

    return (
      <Modal
        title="Добавить участника"
        visible={this.props.selectContragentsModalIsActive}
        width={"60%"}
        footer={false}
        className="my-ant-modal contragent"
        closeIcon={<Close style={{ marginTop: "20px" }} />}
        onOk={this.props.hideSelectContragentsModal}
        onCancel={this.props.hideSelectContragentsModal}
      >
        <Form
          ref={this.contragentRefForm}
          name="basic"
          // initialValues={this.state.initialValues}
          onFinish={this.handleOk}
          onFinishFailed={this.onFinishFailed}

          // style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
        >
          {/* <Row className="search-field">
            <Col span={24} wrap={true}>
              <Form.Item name="search">
                <Input
                  type="text"
                  placeholder="Найти"
                  value={this.state.search}
                  onChange={this.onSearch}
                />
              </Form.Item>
            </Col>
          </Row> */}

          <Row className="data-field">
            <Col>
              <h3
                style={{
                  padding: "14px",
                  "font-family": "inherit",
                  "align-items": "center",
                }}
              >
                Компании
              </h3>
              <Row span={18}>
                <CheckboxGroup
                  style={{
                    width: "100%",
                    display: "flex",
                    paddingLeft: '30px',
                    gap: '10px',
                    "flex-direction": "column",
                    "justify-content": "center",
                    "align-items": "stretch",
                  }}
                  onChange={this.condactorOnChange}
                >
                  {this.state.companiesData &&
                    this.state.companiesData.map((company) => (
                      <Checkbox value={company.id} style={{justifyContent: 'start'}}>
                        {company.legal_name}
                      </Checkbox>
                    ))}
                </CheckboxGroup>
              </Row>
            </Col>
            <Col>
              <Table
                rowSelection={{
                  ...this.rowSelection,
                }}
                columns={columns}
                dataSource={this.state.users}
                className="cantragents-table"
                pagination={{
                  pageSize: 15,
                  total: this.state.pagination_total,
                  onChange: (page) => this.getContractorsPaginate(this.state.condactorQuery, page)
                }}
                locale={{ emptyText: <Empty description={'Нет данных'}/> }}
                loading={this.state.tableLoading}
              />
              <Row style={{ "margin-top": "20px" }}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Выбрать
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}