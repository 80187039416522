import React, { Component } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { Link } from "react-router-dom";
import { investorRegister } from "../../actions/mainActions";

export class RegistrationInvestor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSubmit = data => {
    this.setState({
      loading: true
    });
    investorRegister(data).then(response => {
      this.setState({
        loading: false
      });
      message.success("Успешно зарегистрированы");
      this.props.parentProps.history.push('/auth');
    })
  }

  render() {
    return (
      <Form
        className="regForm"
        name="basic"
        onFinish={this.handleSubmit}
        onFinishFailed={this.onFinishFailed}
      >
        <Col span={12}>
          <h4>Имя</h4>
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: "Введите имя"
              }
            ]}
          >
            <Input placeholder='Введите имя' size='large'/>
          </Form.Item>
          <h4>Фамилия</h4>
          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
                message: "Введите Фамилия"
              }
            ]}
          >
            <Input placeholder='Введите Фамилия' size='large'/>
          </Form.Item>
          <h4>Отчество</h4>
          <Form.Item
            name="middle_name"
          >
            <Input placeholder='Введите Отчество' size='large'/>
          </Form.Item>
          <h4>Email</h4>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Введите email"
              }
            ]}
          >
            <Input placeholder='Введите email' size='large'/>
          </Form.Item>
          <h4>Номер телефона</h4>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Введите номер телефона"
              }
            ]}
          >
            <Input placeholder='Введите номер телефона' size='large'/>
          </Form.Item>
          <h4>Пароль</h4>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Введите пароль"
              }
            ]}
          >
            <Input.Password placeholder='Введите пароль' size='large'/>
          </Form.Item>
          <h4>Повторить пароль</h4>
          <Form.Item
            name="password_confirmation"
            rules={[
              {
                required: true,
                message: "Повторите пароль"
              }
            ]}
          >
            <Input.Password placeholder='Повторите пароль' size='large'/>
          </Form.Item>
        </Col>
        <Form.Item>
          <Button type="primary" htmlType="submit" size='large'
                  loading={this.state.loading}
                  style={{ width: "250px", float: "left" }}>
            Зарегистрироваться
          </Button>
        </Form.Item>
        <Link to='/auth' style={{ float: "left", marginBottom: "85px" }}>
          <span style={{ fontSize: "20px", textDecoration: "underline" }}>Вход</span>
        </Link>
      </Form>
    )
  }
}