import React, { Component } from "react";
import { BackendUrl, getInvestProjects } from "../../actions/mainActions";
import { List, Button, Empty, Spin, Avatar } from 'antd';
import { Link } from "react-router-dom";

class InvestmentProjects extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectsData: [],
      total: [],
      loading: true
    }
  }

  componentDidMount() {
    getInvestProjects().then(response => {
      this.setState({
        projectsData: response.data.data.data,
        total: response.data.data.total,
        loading: false
      })
    })
  }

  goPage = page => {
    this.setState({
      loading: true
    })
    getInvestProjects(page).then(response => {
      this.setState({
        projectsData: response.data.data.data,
        total: response.data.data.total,
        loading: false
      })
    })
  }

  render() {

    const user = JSON.parse(localStorage.getItem('user'))

    const formatToCurrnecy = number => {
      return new Intl.NumberFormat('ru-RU').format(number)
    }

    return (
      <div>
        <h2 className='componentTitle'>
          Инвестиционные проекты
          {
            user.role === 'investor' && 
            <Link to={'/investment/create'}>
              <Button type="primary" size="large" style={{marginLeft: '30px'}}>
                Создать инвестиционный проект
              </Button>
            </Link>
          }
        </h2>
        <Spin spinning={this.state.loading}>
          <List
            className="investment-projects-list"
            locale={{ emptyText: <Empty description={'Нет данных'} image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
            dataSource={this.state.projectsData}
            bordered
            pagination={{
              pageSize: 20,
              total: 20,
              onChange: (page) => this.goPage(page)
            }}
            renderItem={
              project => (
                <List.Item key={project.id}>
                  <List.Item.Meta
                    avatar={
                      <Link to={`/investment/project/${project.id}`}>
                        <Avatar src={project.image_path ? `${BackendUrl}/${project.image_path}` : require('../../assets/images/default-project.jpg')} />
                      </Link>
                    }
                    title={
                      <Link to={`/investment/project/${project.id}`}>
                        <h2>
                          { project.name }
                        </h2>
                      </Link>
                    }
                    description={
                      <div className="project-data">
                        <div className="project-data-item">
                          <h3>Стоимость (тг)</h3>
                          { formatToCurrnecy(project.price) }
                        </div>
                        <div className="project-data-item">
                          <h3>Дестинация</h3>
                          { project.region.name }
                        </div>
                        <div className="project-data-item">
                          <h3>Тип</h3>
                          { project.category.name }
                        </div>
                        <div className="project-data-item">
                          <h3>Статус</h3>
                          { 
                            project.status === '0'
                            ?
                            <span className="status yellow">В процессе</span>
                            :
                            project.status === '1'
                            ?
                            <span className="status green">Принято</span>
                            :
                            project.status === '2'
                            ?
                            <span className="status red">Отклонено</span>
                            :
                            <span className="status gray">Черновик</span>
                          }
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              )
            }
          />
        </Spin>
      </div>
    )
  }
}

export default InvestmentProjects