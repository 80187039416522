import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {MainPanel} from '../pages/mainPage/mainPage';
import {Auth} from '../pages/Auth/index';

export const useRoutes = () => {
    return (
        <Switch>
            <Route>
                <MainPanel path='/main' exact/>
            </Route>
            <Route exact path="/">
                <Redirect to="/auth" component={Auth}/>
            </Route>
            <Route>
                <Auth path='/auth' component={Auth} exact/>
            </Route>
        </Switch>
    )
}
