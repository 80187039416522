import React, {Component} from "react";
import {getTasks} from "../actions/mainActions";
import TimeLine from "react-gantt-timeline";
import {Spin} from "antd";

class Diagrams extends Component {
    state = {
        loading: false,
        tasks: [],
        links: [{id: 1, start: 1, end: 2}],
        data: [],
        data2: [{
            "pID": 1,
            "pName": "Проект 1",
            "pStart": new Date('2020-12-10'),
            "pEnd": new Date('2020-12-12'),
            "pClass": "ggroupblack",
            "pLink": "",
            "pMile": 0,
            "pRes": "Brian",
            "pComp": 0,
            "pGroup": 1,
            "pParent": 0,
            "pOpen": 1,
            "pDepend": "",
            "pCaption": "",
            "pNotes": "Some Notes text"
        }, {
            'pID': 2,
            'pName': 'Задача проекта 1',
            'pStart': '',
            'pEnd': '',
            'pClass': 'ggroupblack',
            'pLink': '',
            'pMile': 0,
            'pRes': 'Brian',
            'pComp': 0,
            'pGroup': 1,
            'pParent': 1,
            'pOpen': 1,
            'pDepend': '1',
            'pCaption': '',
            'pNotes': 'Some Notes text'
        }]
    };

    returnColor = (status) => {
        switch (status) {
            case "doing":
                return "#338DE0";
            case "to_do":
                return "grey";
            case "approval":
                return "orange";
            case "done":
                return "#38B836";
            default:
                return "grey";
        }
    };

    componentDidMount() {
        this.setState({loading: true})
        getTasks().then(res => {
            const tasks = res.data.data;
            const newTasks = tasks.map(el => {
                    return {
                        id: el.id,
                        start: new Date(el.start),
                        end: new Date(el.end),
                        name: el.name,
                        color: this.returnColor(el.status),
                        parent_id: el.parent_id ? el.parent_id : null
                    };
                }
            );
            const links = [];
            tasks.forEach(el => {
                el.parent_id && links.push({
                    id: el.id,
                    start: el.parent_id,
                    end: el.id
                });
            });

            this.setState({
                data: newTasks,
                links: links,
                loading: false
            });
        });

    }


    render() {
        const {data, links, loading} = this.state;
        const params = {
            header: { //Targert the time header containing the information month/day of the week, day and time.
                top: {//Tartget the month elements
                    style: {
                        backgroundColor: "#F2F6FF", color: "#3C4E77", fontSize: 14,
                        height: "30px", display: "flex", alignItems: "center"
                    } //The style applied to the month elements
                },
                middle: {//Tartget elements displaying the day of week info
                    style: {backgroundColor: "#fff", color: "#000", fontSize: 14, height: "30px"}, //The style applied to the day of week elements
                    selectedStyle: {backgroundColor: "#b13525"}//The style applied to the day of week elements when is selected
                },
                bottom: {//Tartget elements displaying the day number or time
                    style: {background: "#F2F6FF", fontSize: 14, color: "#3C4E77", height: "30px"},//the style tp be applied
                    selectedStyle: {backgroundColor: "#b13525", fontWeight: "bold"}//the style tp be applied  when selected
                }
            },
            taskList: {//the right side task list
                title: {//The title od the task list
                    label: "Задачи",//The caption to display as title
                    style: {
                        backgroundColor: "#F2F6FF", borderBottom: "solid 1px silver",
                        fontSize: "14px",
                        color: "#3C4E77", textAlign: "center"
                    }//The style to be applied to the title
                },
                task: {// The items inside the list diplaying the task
                    style: {backgroundColor: "#fff", display: 'block'}// the style to be applied
                },
                verticalSeparator: {//the vertical seperator use to resize he width of the task list
                    style: {backgroundColor: "#338DE0"},//the style
                    grip: {//the four square grip inside the vertical separator
                        style: {backgroundColor: "#fff"}//the style to be applied
                    }
                }
            },
            dataViewPort: {//The are where we display the task
                rows: {//the row constainting a task
                    style: {backgroundColor: "#fff", borderBottom: "solid 0.5px #cfcfcd"}
                },
                task: {
                    showLabel: true,//If the task display the a lable
                    style: {
                        position: "absolute", borderRadius: 14, color: "white",
                        height: "40px",
                        textAlign: "center", backgroundColor: "grey"
                    },
                    selectedStyle: {}//the style tp be applied  when selected
                }
            },
            links: {//The link between two task
                color: "black",
                selectedColor: "#ff00fa"
            }
        };

        return (
            <div>
                <h1 style={{textAlign: 'start'}} className="componentTitle">Диаграмма ганта</h1>
                <Spin spinning={loading}>
                    <TimeLine data={data} links={links} config={params}/>
                </Spin>

                {/*   <JSGanttComponent data={this.state.data2} options={editorOptions}/>*/}
            </div>
        )
    }
}

export default Diagrams;
