import React, { Component } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
import { checkBinRequest, userRegister } from "../../actions/mainActions";


const layout = {
  labelCol: {
    span: 2
  },
  wrapperCol: {
    span: 24
  }
};
const tailLayout = {
  wrapperCol: {
    span: 4
  }
};

export class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      dataBin: {}
    };
  }

  componentDidMount() {

  }

  onFinishFailed = () => {
    message.error("Заполните корректно данные регистрации");
  };
  handleSubmit = (e) => {
    if (!this.state.error) {
      this.setState({
        loading: true
      });
      userRegister(e).then((response) => {
        if(this.props.role === 'root') {
          this.props.updateList();
          this.setState({
            loading: false
          });
        } else {
        message.success("Ожидайте активации учетной записи, администратором", 10);
        this.props.parentProps.history.push("/auth");
        }
      }, err => {
        this.setState({
          loading: false
        });
      });
    } else {
      message.error("Укажите корректный БИН");
    }

  };

  checkBin = e => {
    if (e.length === 12) {
      checkBinRequest(e).then(res => {
        this.setState({
          error: false,
          dataBin: res.data.data

        });
      }, err => {
        this.setState({
          error: true
        });
      });
    }
  };

  render() {
    const { loading, error, dataBin } = this.state;
    return (
      <Form
        className={"regForm"}
        {...layout}
        name="basic"
        onFinish={this.handleSubmit}
        onFinishFailed={this.onFinishFailed}
      >

        <Row gutter={24}>
          <Col span={12}>
            <h4>БИН</h4>
            <Form.Item
              name="bin"
              rules={[
                {
                  required: true,
                  message: "Введите БИН, 12 символов"
                }
              ]}
            >
              <Input placeholder='Введите имя' size='large' minLength={12} maxLength={12}
                     onChange={(e) => this.checkBin(e.target.value)}/>
            </Form.Item>
          </Col>
          <Col span={12} className={"errorBin"}>
            {error && <span className={"dangerText "}>Компании с таким БИН не найдено</span>}


            {!error && !!dataBin.legal_name && <div>
              <span className={"successText "}>
                Есть компания с таким БИН
              </span>
              <div className={"dflex"}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="white"/>
                  </mask>
                  <g mask="url(#mask0)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M10.9394 16.2428L9.52519 17.657L4.57544 12.7072L5.98965 11.293L10.9394 16.2428ZM18.0105 6.34326L19.4247 7.75748L10.9394 16.2428L9.52518 14.8285L18.0105 6.34326Z"
                          fill="#38B836"/>
                  </g>
                </svg>
                <span className={"primaryText mediumFont"} style={{ marginLeft: "20px" }}>
                  {dataBin.legal_name}
                </span>
              </div>
            </div>}


          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <h4>Имя</h4>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Введите имя"
                }
              ]}
            >
              <Input placeholder='Введите имя' size='large'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h4>Email</h4>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Введите email"
                }
              ]}
            >
              <Input placeholder='Введите email' size='large'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <h4>Фамилия</h4>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Введите фамилию"
                }
              ]}
            >
              <Input placeholder='Введите фамилию' size='large'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h4>Пароль</h4>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Введите пароль"
                }
              ]}
            >
              <Input.Password placeholder='Введите пароль' size='large'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <h4>Отчество</h4>
            <Form.Item
              name="middle_name"
              rules={[
                {
                  required: false,
                  message: "Введите отчество"
                }
              ]}
            >
              <Input placeholder='Введите отчество' size='large'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h4>Повторите пароль</h4>
            <Form.Item
              name="c_password"
              rules={[
                {
                  required: true,
                  message: "Введите пароль"
                }
              ]}
            >
              <Input.Password placeholder='Повторите пароль' size='large'/>
            </Form.Item>
          </Col>
        </Row>

        {/*     <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                    <Checkbox style={{float: 'left'}}>Запомнить пароль</Checkbox>
                </Form.Item>*/}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" size='large'
                  loading={loading}
                  style={{ width: "250px", float: "left" }}>
            Отправить запрос
          </Button>
        </Form.Item>
        <Link to='/auth' style={{ float: "left", marginBottom: "85px" }}>
          <span style={{ fontSize: "20px", textDecoration: "underline" }}>Вход</span>
        </Link>
      </Form>
    );
  }
}


export default Registration;
