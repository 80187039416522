import React, { Component } from "react";
import {
    Button,
    Col,
    message,
    Row,
    Select,
    Spin,
} from "antd";
import "../Projects/myprojects.scss";
// import Search from "antd/lib/input/Search";
import moment from "moment";
import Board from "@lourenci/react-kanban";
import { PlusOutlined } from "@ant-design/icons";
import {
    addFile,
    BackendUrl,
    getMembers,
    getProjects,
    getTaskWithFilter,
    newOrdering,
    updateStatus
} from "../../actions/mainActions";
import "@lourenci/react-kanban/dist/styles.css";
import { ReactComponent as Message } from "../../assets/images/icons/message.svg";
import { ReactComponent as Tick } from "../../assets/images/icons/tick.svg";
import { ReactComponent as File } from "../../assets/images/icons/file.svg";
import "leaflet/dist/leaflet.css";
import { Link } from "react-router-dom";
import "moment/locale/ru";
import { TaskView } from './taskView';
import { TaskAddEdit } from './taskAddEdit';
// import TaskListsByStatus from './taskListsByStatus';
const { Option } = Select;
// import { Option } from "antd/lib/mentions";

// const { TabPane } = Tabs;

export class TaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectFilterId: 'all',
            statusFilter: 'all',
            activeTab: 'board',
            changeLog: [],
            editId: null,
            edit: false,
            center: null,
            task: {},
            taskVisible: false,
            projectsList: [],
            data: [],
            filterProps: {
                memberId: 'all',
                projectId: 'all'
            },
            allMembers: [],
            selectedRowKeys: [],
            avatarList: [],
            filesList: [],
            loadingList: false,
            isVisible: false,
            mapObjectsArr: [],
            initialValues: {},
            visible: false,
            project_name: "",
            project_id: null,
            id_for_subTask: null,
            task_data: {
                name: "",
                description: "",
                avatar: null,
                priority: "normal",
                type: "task",
                files: [],
                managers: [],
                members: [],
                parent_id: "1",
                dependence_id: "1",
                start: moment().format("YYYY-MM-DD, hh:mm"),
                end: moment().format("YYYY-MM-DD, hh:mm")
            },
            board: {
                columns: [
                    {
                        id: "to_do",
                        title: "Backlog",
                        type: "to_do",
                        class: "one",
                        cards: []
                    },
                    {
                        id: "doing",
                        title: "В работе",
                        type: "doing",
                        class: "two",
                        cards: []
                    },
                    {
                        id: "approval",
                        title: "На утверждении",
                        class: "three",
                        type: "approval",
                        cards: []
                    },
                    {
                        id: "done",
                        title: "Готов",
                        type: "done",
                        class: "four",
                        cards: []
                    }
                ]
            },
            newCardTitle: "",
            newCardPerson: "",
            newCardDate: "",
            newCardManager: "",
            showMap: false,
            selectedTask: null,
            filterDate: ['day','week','month']
        };
    }

    showTask = () => {
        this.setState({
            taskVisible: true
        });

    };
    renderOperateionType = type => {
        switch (type) {
            case "edit":
                return "Отредактировал(а)";
            case "create":
                return "Создал(а)";
            case "delete":
                return "Удалил(а)"
            default:
                return type
        }
    };
    renderPopup = (el) => {
        return <div style={{ minWidth: "250px" }}>
            <Row gutter={16}>
                {el.image.length > 0 &&
                    <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                        <img style={{ width: "100%" }} src={BackendUrl + el.image[0].url} alt={el.image[0].name} />
                    </Col>}
                <Col span={el.image.length > 0 ? 16 : 24}>
                    <h4>{el.name}</h4>
                    <p>{el.description}</p>
                    <p>Дата завершения: {moment(el.end_date).format("DD.MM.YYYY")}</p>
                </Col>
            </Row>
        </div>;
    };


    openCardIfUrlTasiIdExist = (id) => {
        this.setState({
            selectedTask: id
        });
        this.showTask(id);
    };
    uploadFile = ({ onSuccess, onError, file }) => {
        const formData = new FormData();
        formData.append("files[]", file);
        addFile(formData).then(res => {
            onSuccess(file);
            const filesList = this.state.filesList;
            filesList.push({
                uid: file.uid,
                name: file.name,
                status: "done",
                response: res.data.data[0]
            });
            this.setState({
                filesList: [...filesList]
            });
        });
    };

    getAllMembers = () => {
        getMembers().then(res => {
            const allMembers = res.data.data;
            this.setState({
                allMembers
            })
        }
        );
    }

    getAllProjects = () => {
        const { editId } = this.props;
        getProjects().then(res => {
            const projectsList = editId ? res.data.data.filter(el => el.id !== editId) : res.data.data;
            this.setState({
                projectsList
            })
        })
    }

    getListTasks = async (memberId, projectId,date) => {
        // if (this.state.loadingList) return

        this.setState({
            loadingList: true
        })
        const response = await getTaskWithFilter('all', projectId, memberId,date)
        console.log(response,'response')
        // .then((response) => {
            const columns = [...this.state.board.columns];
            columns.forEach(el => el.cards = []);
            const tasksList = !!response.data.data ? Object.values(response.data.data) : [];
            tasksList.map((item) => {
                const column = columns.find(el => el.type === item.status);
                column.cards.push(
                    {
                        key: item.id,
                        id: item.id,
                        title: item.name,
                        priority: item.priority,
                        end: item.end,
                        files: item.files,
                        perform_percent: item.perform_percent ? item.perform_percent : '0',
                        controlPerson: item.managers,
                        resultPerson: item.members,
                        endDate: item.end,
                        status: item.status,
                        project_id: item.project_id,
                        toStart: false
                    }
                );
                return true
            });
            this.setState({
                data: tasksList,
                loadingList: false,
                filterProps: {
                    memberId, projectId
                }
            });
        // });
    }

    onFinishFailed = () => {
        message.error("Проверьте введенный поля");
    };
    renderColumnHeader = (col, bag) => {
        return <div className={"kanbanHeader " + col.class}>
            <span className={"colKanabTitle"}>{col.title}</span>
            <Button size={"small"} onClick={() => this.showModal(col)}><PlusOutlined /></Button>
        </div>;
    };
    showModal = (col) => {
        this.setState({
            isVisible: true,
            selectedColumn: col
        });
    };
    handleEdit = (task) => {
        this.setState({
            isVisible: true,
            taskVisible: false,
            edit: true,
            taskId: task.id,
        })
    }
    hideModal = () => {
        this.setState({
            isVisible: false,
            edit: false,
            taskVisible: false
        });
    };
    hideAndRefresh = () => {
        this.getListTasks();
        this.hideModal();
        this.props.history.push('/board');
    }

    loadOn = () => {
        this.setState({
            loadingList: true
        });
    };
    loadOff = () => {
        this.setState({
            loadingList: false
        });
    };


    renderColor = (status, endDate) => {
        switch (status) {
            case 'done':
                return 'greenIconStroke'
            default:
                return moment(endDate).isBefore(moment(), 'day') ? 'redIconStroke' : 'yellowIconStroke'
        }
    }

    renderCard = (card, bag) => {
        return <div className={"cardKanban"}>
            <div>
                <Link
                    onClick={() => this.setState({
                        taskVisible: true
                    })}
                    to={`/board/${card.id}`}>
                    <Row>
                        <Col span={24} className={"titleCardKanban"}> {card.title} </Col>
                    </Row>
                    <Row>
                        <div className="progress-bar">
                            <div className={"progress-bar-inner " + (card.perform_percent < 50 ? 'small' : (card.perform_percent < 90 ? 'medium' : 'large'))} style={{ width: card.perform_percent + '%' }}></div>
                        </div>
                        <div className="progress-data">
                            <div className="progress-data-title">Выполнено</div>
                            <div className="progress-data-value">{card.perform_percent}%</div>
                        </div>
                    </Row>
                    <Row>
                        <div className="priority">
                            {card.priority === 'critical' ?
                                <div className="high">Высокий</div>
                                : null}
                            {card.priority === 'normal' ?
                                <div className="medium">Средний</div>
                                : null} 
                            <div className="expire">до {moment(card.end).format('DD.MM.YYYY')}</div>
                        </div>
                    </Row>
                    <Row>
                        <div className="infobar">
                            <div className="members">
                                {card.resultPerson.length > 0 && card.resultPerson.map((el) => <div className={"userInTask"}
                                    key={el.id}>
                                    <div className={"avatarCanban"} style={{display: 'flex'}}>
                                    {el.avatar ?
                                        <img src={BackendUrl + el.avatar} alt="" style={{objectFit: 'cover', width: 28, height: 28}} />
                                    :
                                        <img src="https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png" alt="" style={{objectFit: 'cover', width: 28, height: 28}} />
                                    }
                                    </div>
                                    {/* <div className={"avatarCanban"}
                                        style={el.avatar ? { backgroundImage: `url(${encodeURI(BackendUrl + el.avatar)})` } : { backgroundImage: "url(https://image.flaticon.com/icons/png/512/64/64572.png)" }} /> */}
                                </div>
                                )}
                            </div>
                            <div className="info">
                                {/* <Message style={{ marginRight: 5, fill: '#96A0B5' }} /> 0
                                <Tick style={{ marginLeft: 10, marginRight: 3, fill: '#96A0B5' }} /> 0 */}
                                <File style={{ marginLeft: 10, marginRight: 3, fill: '#96A0B5' }} /> {card.files.length}
                            </div>
                        </div>
                    </Row>

                    {/* <Row>
                        <Col span={24} className={"timeBoxKanban"}><span
                            className={"cardKanbanEndDate"}>{card.endDate ? "срок задачи до " + moment(card.endDate).format("DD.MM.YYYY") : "Без срока"}</span><span
                                className={card.status !== 'to_do' ? "blueIconFill arrowToIcon" : "arrowToIcon"}><ArrowTo /></span>
                            <span
                                className={this.renderColor(card.status, card.endDate)}><Flag />
                            </span></Col>
                    </Row> */}
                </Link>
                {/* <Row gutter={8}>
                    {card.controlPerson && <Col span={12}>
                        <h3>Руководитель</h3>

                        {card.controlPerson.length > 0 && card.controlPerson.map((el, i) => <div
                            className={"userInTask"} key={i}>
                            <div className={"avatarCanban"}
                                style={!!el.avatar ? { backgroundImage: `url(${BackendUrl + el.avatar})` } : { backgroundImage: "url(https://image.flaticon.com/icons/png/512/64/64572.png)" }} />
                            <span className={"kanbanFio"}> <Link
                                to={`profile/${el.id}`}>{el.first_name} {el.last_name}</Link></span>
                        </div>)}
                    </Col>
                    }
                    {card.resultPerson && <Col span={12}>
                        <h3>Ответственный</h3>
                        {card.resultPerson.length > 0 && card.resultPerson.map((el, i) => <div className={"userInTask"}
                            key={i}>
                            <div className={"avatarCanban"}
                                style={!!el.avatar ? { backgroundImage: `url(${BackendUrl + el.avatar})` } : { backgroundImage: "url(https://image.flaticon.com/icons/png/512/64/64572.png)" }} />
                            <span
                                className={"kanbanFio"}> <Link
                                    to={`profile/${el.id}`}>{el.first_name} {el.last_name}</Link></span>
                        </div>)}
                    </Col>}
                </Row> */}
            </div>
        </div>;
    }

    handleCancel = (e) => {
        const projectFilterId = this.props.match.params.projectId;
        const statusFilter = this.props.match.params.status;
        const selectedTask = this.props.match.params.taskId;
        const userId = this.props.match.params.userId;
        this.props.history.push(selectedTask ? `/list/${statusFilter}/${projectFilterId}/${userId}/0` : '/board');
        this.getListTasks()
        this.setState({
            taskVisible: false,
            taskId: null
        });
    }

    updateStatusCheck = (c, d, r, e) => {

        r.status = d.toColumnId;
        if (c.fromColumnId !== d.toColumnId) {
            const data = {
                id: r.id,
                status: d.toColumnId
            };
            this.loadOn();
            updateStatus(data).then(res => {
                message.success("Статус изменен");
                this.loadOff();
            }, err => this.loadOff());
            const column = e.columns.find(el => el.id === d.toColumnId);
            const newOrder = column.cards.map((el, i) => {
                return {
                    id: el.id,
                    order: i,
                    key: el.id
                };
            });
            this.loadOn();
            newOrdering({ tasks: newOrder }).then(res => {
                this.loadOff();
            }, err => this.loadOff());
            const columnold = e.columns.find(el => el.id === c.fromColumnId);
            const newOrderOld = columnold.cards.map((el, i) => {
                return {
                    id: el.id,
                    order: i,
                    key: el.id
                };
            });
            this.loadOn();
            newOrderOld.length > 0 && newOrdering({ tasks: newOrderOld }).then(res => {
                this.loadOff();
            }, err => this.loadOff());

        } else {
            const column = e.columns.find(el => el.id === d.toColumnId);
            const newOrder = column.cards.map((el, i) => {
                return {
                    id: el.id,
                    order: i,
                    key: el.id
                };
            });
            this.loadOn();
            newOrdering({ tasks: newOrder }).then(res => {
                message.success("Порядок изменен");
                this.loadOff();
            }, err => this.loadOff());
        }
    };

    componentDidMount() {
        this.getListTasks();
        const taskId = this.props.match.params.id;
        taskId && this.openCardIfUrlTasiIdExist(taskId);
        const path = this.props.match.path.split('/')[1]

        this.getAllMembers()
        this.getAllProjects()

        if (path === 'list') {
            if (this.props.match.params.taskId && this.props.match.params.taskId > 0) {
                this.setState({
                    activeTab: path,
                    projectFilterId: this.props.match.params.projectId,
                    statusFilter: this.props.match.params.status,
                    selectedTask: this.props.match.params.taskId
                })
                this.showTask(this.props.match.params.taskId)
            } else {
                this.setState({
                    activeTab: path,
                    projectFilterId: this.props.match.params.projectId,
                    statusFilter: this.props.match.params.status
                })
            }
        } else {
            this.setState({
                activeTab: path
            })
        }
    }

    setActivaTab = (tab) => {
        this.props.history.push(`/${tab === 'list' ? 'list/all/all/all/0' : tab}`)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const taskId = this.props.match.params.id || this.props.match.params.taskId;
        if (taskId && taskId !== '0' && taskId !== prevState.selectedTask) {
            taskId && this.openCardIfUrlTasiIdExist(taskId);
        }
        if(prevState.taskId !== this.state.taskId){
            this.getListTasks();
        }
    }

    async changeMember(id) {
        await this.getListTasks(id, this.state.filterProps.projectId)
    }

    async changeProject(id) {
        await this.getListTasks(this.state.filterProps.memberId, id)
    }

    async changeDate(date) {
        await this.getListTasks(this.state.filterProps.memberId, this.state.filterProps.projectId, date)
    }

    render() {
        const { allMembers, projectsList, filterDate } = this.state;
        // const { history, match } = this.props;
        const sortDateName = (item) => {
            switch (item) {
                case 'day':
                    return 'День'
                case 'week':
                    return 'Неделя'
                case 'month':
                    return 'Месяц'
                default:
                    break;
            }
        }
        return (
            <div>
                {/* <h2 className="componentTitle">Задачи</h2> */}
                <Spin spinning={this.state.loadingList}>
                    <Row className={'toolbarInner filterTasks'}>
                        <Select
                            style={{ float: 'left', width: '290px', height: '66px' }}
                            placeholder="Пользователь"
                            onChange={id => this.changeMember(id)}
                            showArrow={false}
                        // value={status}
                        >
                            <Option value="all">
                                Все
                            </Option>
                            {allMembers.map((item) => (
                                <Option value={item.id} key={item.id}>
                                    {item.first_name} {item.last_name}
                                </Option>
                            ))}
                        </Select>

                        <Select
                            style={{ float: 'left', width: '290px', height: '66px', marginLeft: '10px' }}
                            placeholder="Проект"
                            onChange={id => this.changeProject(id)}
                            showArrow={false}
                            // value={status}
                        >
                            <Option value="all">
                                Все
                            </Option>
                            {projectsList.map((item) => (
                                <Option value={item.id} key={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>

                        <Select
                            style={{ float: 'left', width: '290px', height: '66px', marginLeft: '10px' }}
                            placeholder="Дата"
                            onChange={date => this.changeDate(date)}
                            showArrow={false}
                            // value={status}
                        >
                            <Option value="all">
                                Все
                            </Option>
                            {filterDate.map((item,idx) => (
                                <Option value={item} key={idx}>
                                    {sortDateName(item)}
                                </Option>
                            ))}
                        </Select>
                    </Row>
                    
                    <Row gutter={16}>
                        <Board
                            renderColumnHeader={this.renderColumnHeader}
                            renderCard={this.renderCard}
                            initialBoard={this.state.board}
                            disableColumnDrag
                            onCardDragEnd={(e, r, c, d) => {
                                this.updateStatusCheck(c, d, r, e);
                            }}
                        />
                    </Row>
                </Spin>

                {/* <Spin spinning={this.state.loadingList}>
                    <Tabs defaultActiveKey="board" onChange={this.setActivaTab} activeKey={activeTab}>
                        <TabPane tab="Мои задачи" key="board">
                            <Row className={"toolbarInner"}>
                                <Col span={8}>
                                    <Search
                                        className={"innerSearch"}
                                        placeholder="Поиск по проектам"
                                        onSearch={onSearch}
                                        size="small"
                                    />
                                </Col>
                                <Col span={6} style={{ display: "flex" }}>
                                    <Button type="primary" size="large" onClick={this.showModal}>
                                        Добавить Задачу <PlusCircleOutlined />
                                    </Button>
                                </Col>
                            </Row>

                        </TabPane>
                        <TabPane tab="Список задач" key="list">
                            <TaskListsByStatus history={history} match={match} />
                        </TabPane>
                    </Tabs>
                </Spin> */}
                <TaskAddEdit
                    editId={this.state.editId}
                    hideModal={this.hideAndRefresh}
                    isVisible={this.state.isVisible}
                    onFinishFailed={this.onFinishFailed}
                />
                {
                    this.state.taskVisible &&
                    <TaskView
                        taskId={this.state.selectedTask}
                        handleEdit={this.handleEdit}
                        deleteTask={this.deleteTask}
                        getListTasks={this.getListTasks}
                        taskVisible={this.state.taskVisible}
                        handleCancel={this.handleCancel}
                        selectedMapObject={this.state.selectedMapObject}
                        inputValue={this.state.inputValue}
                        renderPopup={this.renderPopup}
                        changeLog={this.state.changeLog}
                        center={this.state.center}
                        points={this.state.points}
                        projectName={this.state.project_name}
                        renderOperateionType={this.renderOperateionType}
                    />
                }
            </div>
        );
    }
}
