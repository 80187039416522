import React, { Component } from "react";
import { Button, Checkbox, Col, Form, Input, message, Modal, Row } from "antd";
import { Link } from "react-router-dom";
import "./auth.scss";
import { resetPasswordByEmail, userLogin, getProfile } from "../../actions/mainActions";

export let token = localStorage.getItem('token');
export let first_name = localStorage.getItem('first_name');
export let last_name = localStorage.getItem('last_name');
export let middle_name = localStorage.getItem('middle_name');
token = !!token && token !== 'undefined' ? JSON.parse(token) : null;
const layout = {
    labelCol: {
        span: 2
    },
    wrapperCol: {
        span: 8,
    },
};
const tailLayout = {
    wrapperCol: {
        span: 4,
    },
};

export class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleReset: false,
            loading: false
        };
    }

    componentDidMount() {
        localStorage.removeItem('token')
    }

    handleSubmit = (e) => {
        this.setState({
            loading: true
        });
        const form = new FormData();


        /*
        const objToAuth = e;
        objToAuth.grant_type = "password";
        objToAuth.client_id = "1";
        objToAuth.client_secret = "PZaq0M5kBIn7UqLXctetQkMusA6CCHgqwANLiFzX";
        */
        form.append("grant_type", "password");
        form.append("client_id", "1");
        form.append("client_secret", "PZaq0M5kBIn7UqLXctetQkMusA6CCHgqwANLiFzX");
        form.append("username", e.username);
        form.append("password", e.password);
        userLogin(form).then(response => {
            getProfile().then(res => {
                localStorage.setItem('user', JSON.stringify(res.data.data))
                if (res.data.data.role === 'investor') {
                    this.props.setActiveMenu('investment-main');
                    this.props.parentProps.history.push('/investment/main');
                } else {
                    this.props.setActiveMenu('panel');
                    this.props.parentProps.history.push('/panel');
                }
                this.setState({
                    loading: false
                });
                message.success('Успешно авторизованы');
                this.props.getLogin();
            })
            }, err => {
                message.error('Неверные логин или пароль')
                this.setState({
                    loading: false
                })
            }
        )
    }
    onFinishFailed = () => {
        message.error('Заполните корректно данные входа');
    }

    resetPassword = (e) => {
        this.setState({
            loading: true
        });
        resetPasswordByEmail(e).then(res => {
            this.setState({
                loading: false
            });
            message.success('Пароль сброшен, дальнейшие действия высланы на почту')
        },err => {
            message.error('Проверьте введеный Email');
            this.setState({
                loading: false
            });
        })
    }

    render() {
        const {activeMenu, loading} = this.state;
        return (
            <React.Fragment>
                <Form
                    style={{marginLeft: '100px'}}
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.handleSubmit}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Row style={{marginBottom: '45px'}}>
                        <h1 className={'pageTitle'}>ВХОД</h1>
                    </Row>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Введите логин',
                            },
                        ]}
                    >
                        <Input
                            placeholder='Введите логин'
                            size='large'
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Введите пароль',
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder='Введите пароль'
                            size='large'/>
                    </Form.Item>

                    <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox style={{float: 'left'}}>Запомнить пароль</Checkbox>
                    </Form.Item>

                    <Form.Item {...tailLayout} >
                        <Button loading={loading} type="primary"
                                htmlType="submit" size='large' style={{width: '150px', float: 'left'}}>
                            Войти
                        </Button>
                    </Form.Item>
                    <Link to='/registration' style={{float: 'left', marginBottom: '85px'}} activeMenu={activeMenu}>
                        <span style={{fontSize: '20px', textDecoration: 'underline'}}>Регистрация</span>
                    </Link>
                    <span className={'fotgetBtn'} onClick={() => this.setState({visibleReset: true})}
                          style={{fontSize: '20px', textDecoration: 'underline'}}>Забыли пароль?</span>
                </Form>
                <Modal
                    title="Восстановление пароля"
                    centered
                    width={600}
                    footer={null}
                    visible={this.state.visibleReset}
                    onOk={() => this.setState({visibleReset: false})}
                    onCancel={() => this.setState({visibleReset: false})}
                >


                    <Form
                        name="basic"
                        onFinish={this.resetPassword}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите Email',
                                        },
                                    ]}
                                >
                                    <Input type={"email"}
                                           width={'100%'}
                                           placeholder='Введите Email'
                                           size='large'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item {...tailLayout} style={{float: 'right'}}>
                                    <Button loading={loading} type="primary"
                                            htmlType="submit" size='large'>
                                        Восстановить
                                    </Button>
                                </Form.Item>
                                <Form.Item {...tailLayout} style={{float: 'right', marginRight: '30px'}}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({visibleReset: false})
                                    }} type="default" size='large'>
                                        Отмена
                                    </Button>
                                </Form.Item>
                            </Col>


                        </Row>
                    </Form>
                </Modal>
            </React.Fragment>
        )
    }
}


export default Auth;
  
