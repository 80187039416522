import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Select,
  DatePicker,
  message,
  Progress,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import "@lourenci/react-kanban/dist/styles.css";
import { ReactComponent as Arrow } from "../../assets/images/icons/arrow.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/calendar.svg";

import TextArea from "antd/es/input/TextArea";
import "./panel.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { Redirect } from "react-router-dom";
import {
  BackendUrl,
  newOrdering,
  updateStatus,
} from "../../actions/mainActions";
import { getProjects } from "../../actions/mainActions";
import { getMembers } from "../../actions/mainActions";
import GlobalMapOnMainPanel from "../ProjectsMap/globalMapOnMainPanel";
import LineChart from "./components/LineChart";
import ProgressBar from "./components/ProgressBar";
import DonutChart from "./components/DonutChart";
import TaskCard from "./components/TaskCard";
import EmployeeCard from "./components/EmployeeCard";

const { Option } = Select;

export class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      range: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: "selection",
        },
      ],
      format: "DD.MM.YYYY",
      visible: false,
      board: {
        columns: [
          {
            id: "to_do",
            title: "Backlog",
            type: "to_do",
            cards: [],
          },
          {
            id: "doing",
            title: "В работе",
            type: "doing",
            cards: [],
          },
          {
            id: "approval",
            title: "На утверждении",
            type: "approval",
            cards: [],
          },
          {
            id: "done",
            title: "Готово",
            type: "done",
            cards: [],
          },
        ],
      },
      newCardTitle: "",
      newCardPerson: "",
      newCardDate: "",
      employees: [
        { id: 1, name: 'nurik' },
        { id: 2, name: 'nurik' },
        { id: 4, name: 'nurik' },
        { id: 3, name: 'asd' },
        { id: 5, name: 'nurik' },
        { id: 6, name: 'nurik' },
        { id: 7, name: 'nurik' },
        { id: 8, name: 'nurik' },
        { id: 9, name: 'nurik' },
        { id: 10, name: 'nurik' },
        { id: 11, name: 'nurik' }
      ]
    };
  }

  showModal = (col) => {
    this.setState({
      visible: true,
      notAuth: false,
      selectedColumn: col,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    getProjects().then((response) => {
      this.setState({
        projectsList: !!response.data.data
          ? Object.values(response.data.data)
          : [],
      });
    });
    getMembers().then((response) => {
      this.setState({
        allMembers: response.data.data,
      });
    });
  }

  confirmAddCard = () => {
    const columns = { ...this.state.selectedColumn };
    const board = { ...this.state.board };
    const colInBoard = board.columns.find((el) => el.id === columns.id);
    colInBoard.cards.push({
      id: new Date().getTime(),
      title: this.state.newCardTitle,
      controlPerson: {
        url: "https://uifaces.co/our-content/donated/gPZwCbdS.jpg",
        name: "Иван Иванов",
      },
      resultPerson: {
        url: `${BackendUrl}`,
        name: this.state.newCardPerson,
      },
      endDate: this.state.newCardDate,
      status: "toStart",
      toStart: false,
    });
    this.setState({
      board,
    });
  };

  loadOn = () => {
    this.setState({
      loadingList: true,
    });
  };

  loadOff = () => {
    this.setState({
      loadingList: false,
    });
  };

  updateStatusCheck = (c, d, r, e) => {
    if (c.fromColumnId !== d.toColumnId) {
      const data = {
        id: r.id,
        status: d.toColumnId,
      };
      this.loadOn();
      updateStatus(data).then(
        (res) => {
          message.success("Статус изменен");
          this.loadOff();
        },
        (err) => this.loadOff()
      );

      const column = e.columns.find((el) => el.id === d.toColumnId);
      const newOrder = column.cards.map((el, i) => {
        return {
          id: el.id,
          order: i,
        };
      });
      this.loadOn();
      newOrdering({ tasks: newOrder }).then(
        (res) => {
          this.loadOff();
        },
        (err) => this.loadOff()
      );

      const columnold = e.columns.find((el) => el.id === c.fromColumnId);
      const newOrderOld = columnold.cards.map((el, i) => {
        return {
          id: el.id,
          order: i,
        };
      });
      this.loadOn();
      newOrderOld.length > 0 &&
        newOrdering({ tasks: newOrderOld }).then(
          (res) => {
            this.loadOff();
          },
          (err) => this.loadOff()
        );
    } else {
      const column = e.columns.find((el) => el.id === d.toColumnId);
      const newOrder = column.cards.map((el, i) => {
        return {
          id: el.id,
          order: i,
        };
      });
      this.loadOn();
      newOrdering({ tasks: newOrder }).then(
        (res) => {
          message.success("Порядок изменен");
          this.loadOff();
        },
        (err) => this.loadOff()
      );
    }
  };

  setGlobalMapFullWidth = () => {
    this.setState({
      globalMapFullWidth: !this.state.globalMapFullWidth,
    });
  };
  render() {
    return (
      <div className="panel">
        {this.state.notAuth && <Redirect to={"/auth"} />}
        <Row>
          <h2 className="componentTitle blueText">
            Добро пожаловать в YOTE.KZ!
          </h2>
        </Row>
        {/* <div class="cards-panel">
          <div class="rgb-panel">
            <div class="rgb companies">
              <div class="rgb-img">
                <img src="@/assets/MainPage/companies.svg" alt="" />
              </div>
              <div class="rgb-text">
                <p>Компаний</p>
                <b>{1}</b>
              </div>
            </div>
            <div class="rgb projects">
              <div class="rgb-img">
                <img src="@/assets/MainPage/projects.svg" alt="" />
              </div>
              <div class="rgb-text">
                <p>Проекты</p>
                <b>{5}</b>
              </div>
            </div>
            <div class="rgb tasks">
              <div class="rgb-img">
                <img src="@/assets/MainPage/tasks.svg" alt="" />
              </div>
              <div class="rgb-text">
                <p>Задачи</p>
                <b>{10}</b>
              </div>
            </div>
          </div>
          <div class="todo-panel">
            <p>Задачи на сегодня</p>
            <div class="todo-cards">
              <TaskCard progress={10} />
              <TaskCard progress={20} />
              <TaskCard progress={30} />
              <TaskCard progress={40} />
              <TaskCard progress={50} />
              <TaskCard progress={60} />
              <TaskCard progress={70} />
              <TaskCard progress={80} />
              <TaskCard progress={90} />
              <TaskCard progress={100} />
            </div>
          </div>
          <div class="top-employees">
            <p>Топ-10 сотрудников по KPI</p>
            <div class="employee-cards">
              {
                this.state.employees.map(employee => (
                  <EmployeeCard id={employee.id} name={employee.name} />
                ))
              }
            </div>
          </div>
        </div>
        <a href="/charts" class="visualisation">
          Графические представления данных
          <svg
            class="chevron-right"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              class="chevron-right"
              fill="#080A1C"
              d="M13.586 12l-5.293 5.293a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414l-6-6a1 1 0 00-1.414 1.414L13.586 12z"
            />
          </svg>
        </a>
        <LineChart />
        <div className="two-charts">
          <DonutChart completed={10} closed={20} />
          <ProgressBar />
        </div> */}
      </div>
    );
  }
}

export default Panel;
