import React, { Component } from "react";
import { Circle, Map, Marker, Polygon, Polyline, Popup, TileLayer, withLeaflet } from "react-leaflet";
import { ReactLeafletSearch } from "react-leaflet-search";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { ReactComponent as Pointer } from "../../assets/images/icons/pointer.svg";
import { ReactComponent as Poly } from "../../assets/images/icons/poly.svg";
import { ReactComponent as Road } from "../../assets/images/icons/road.svg";
import moment from "moment";
import "antd/dist/antd.css";
import { Button, Col, Row, Select, Spin } from "antd";
import { BackendUrl, getAllTasksMapObjects, getProblems, getProject, getTask } from "../../actions/mainActions";
import { Link } from "react-router-dom";
import { iconMarkerError } from "./globalMap";

const { Option } = Select;

export class GlobalMapOnMainPanel extends Component {
    state = {
        projectDetails: null,
        taskDetails: null,
        currentSliderDateValue: 0,
        min: 0,
        max: 0,
        dateTimelinePoints: {},
        selectedMapObject: null,
        pointersId: [],
        loading: false,
        tasksWithMarkers: [],
        showHelpTips: true,
        activeAddItemType: ["point", "line", "poligon"],
        inputValue: 1,
        problems: [],
        center: {
            lat: 48.02465582510573,
            lng: 67.4176107053263
        },
        points: [],
        zoom: 6,
        newMapObject: {
            title: "",
            description: "",
            coordinates: [],
            type: ""
        }
    };
    onChange = value => {
        this.setState({
            inputValue: value
        });
    };
    changeTool = (e) => {
        const { activeAddItemType } = this.state;
        const index = activeAddItemType.indexOf(e);
        if (index >= 0) {
            activeAddItemType.splice(index, 1);
        } else {
            activeAddItemType.push(e);
        }
        this.setState({
            activeAddItemType: [...activeAddItemType]
        });
    };


    componentDidMount() {
        this.props.setGlobalMapFullWidth();
        this.getAllObjects();
        this.getMapProblems();
    }


    getAllObjects() {
        this.setState({
            loading: true
        });
        getAllTasksMapObjects().then(res => {
            this.setState({
                tasksWithMarkers: res.data.data,
                loading: false
            })
            this.onTargetChangeTask(null);
        });

    }

    setPointToMapByTask = (data) => {
        let newArrayPoints = data;
        if (newArrayPoints && newArrayPoints.length > 0) {
            newArrayPoints.sort(function (a, b) {
                return new Date(b.end_date) - new Date(a.end_date);
            });
            newArrayPoints.reverse();
            const min = moment(newArrayPoints[0].end_date).valueOf();
            const max = moment(newArrayPoints[newArrayPoints.length - 1].end_date).valueOf();
            const sortedDatesArray = newArrayPoints.map(el => el.end_date);
            const objOfDate = {};
            objOfDate[moment().valueOf()] = "";
            /*            const step = 100 / sortedDatesArray.length;*/
            sortedDatesArray.forEach((el, i) => {
                objOfDate[moment(el).valueOf()] = "";
            });
            newArrayPoints = newArrayPoints.map((el) => {
                const newEl = el;
                newEl.key = el.id;
                if (el.type === "line" || el.type === "poligon") {
                    newEl.coordinates = el.coordinatesArr.map(coord => [coord.latitude, coord.longitude]);
                    return newEl;
                } else if (el.type === "point") {
                    newEl.coordinates = [el.coordinatesArr[0].latitude, el.coordinatesArr[0].longitude];
                    return newEl;
                } else {
                    return null;
                }
            });
            this.setState({
                points: newArrayPoints,
                dateTimelinePoints: objOfDate,
                selectedMapObject: null,
                min,
                max,
                loading: false
            });
        }
    }

    renderIconFunc = (icon) => {
        switch (icon) {
            case "building":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/building.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "hotel":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/hotel.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "roads":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/road.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "walker":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/walker.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "gas":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/gas.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "electro":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/electro.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "com":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/com.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "wc":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/wc.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "food":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/food.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "park":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/park.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "monument":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/monument.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "trees":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/trees.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            default:
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/default.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });

        }
    };
    onTargetChangeTask = id => {
        const { tasksWithMarkers } = this.state;
        const task = tasksWithMarkers.find(el => el.id === id);
        let map_objects = [];
        if (task) {
            map_objects = task.map_objects;
        } else {
            tasksWithMarkers.forEach(el => {
                map_objects = map_objects.concat(el.map_objects)
            });

        }
        this.setPointToMapByTask(map_objects);
        // debugger
        map_objects[0] && this.setState({
            center: [map_objects[0].coordinatesArr[0].latitude, map_objects[0].coordinatesArr[0].longitude],
            zoom: 18
        })
    }
    onTargetChange = (id) => {
        const object = this.state.points.find(el => el.id === id);
        const newCenter = [object.coordinatesArr[0].latitude, object.coordinatesArr[0].longitude];
        this.onMarkerOpen(object);
        this.setState({
            center: newCenter,
            zoom: 18
        });
    };

    onMarkerOpen = (el) => {
        this.setState({
            selectedMapObject: el.id
        });

        if (!!el.project_id) {
            getProject(el.project_id).then(res => {
                this.setState({
                    projectDetails: res.data.data
                });
            },
                err => {
                    this.setState({
                        projectDetails: null
                    });
                }
            )
        } else {
            this.setState({
                projectDetails: null
            });
        }

        getTask(el.task_id).then(res => {
            this.setState({
                taskDetails: res.data.data
            });
        }, err => {
            this.setState({
                taskDetails: null
            });
        }
        );
    };

    renderPopup = (el) => {
        return <div style={{ minWidth: "250px" }}>
            <Row gutter={16}>
                {el.image.length > 0 &&
                    <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                        <img style={{ width: "100%" }} src={BackendUrl + el.image[0].url} alt={el.image[0].name} />
                    </Col>}
                <Col span={el.image.length > 0 ? 16 : 24}>
                    <h4>{el.name}</h4>
                    <p>{el.description}</p>
                    <p>Дата завершения: {moment(el.end_date).format("DD.MM.YYYY")}</p>
                    <Link to={`/board/${el.task_id}`}>Перейти к задаче</Link>
                </Col>
            </Row>
        </div>;
    };
    getMapProblems = () => {
        getProblems().then(res => {
            const problems = res.data.data.data;
            this.setState((s) => ({
                ...s,
                problems: [...problems]
            }))
        })
    }
    renderPopup = (el) => {
        return <div style={{ minWidth: "250px" }}>
            <Row gutter={16}>
                {el.image && el.image.length > 0 &&
                    <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                        <img style={{ width: "100%" }} src={BackendUrl + el.image[0].url} alt={el.image[0].name} />
                    </Col>}
                <Col span={el.image && el.image.length > 0 ? 16 : 24}>
                    <h4>{el.name}</h4>
                    <p>{el.description}</p>
                    <p>Дата завершения: {moment(el.end_date).format("DD.MM.YYYY")}</p>
                    {/* {el.task_id && <Link onClick={() => {
                        this.props.setActiveMenu('tasklist');
                        this.props.globalMapFullWidth && this.props.setGlobalMapFullWidth()
                    }
                    } to={`/board/${el.task_id}`}>Перейти к задаче <br /><br /></Link>}

                    <Link onClick={() => {
                        this.props.setActiveMenu("projects");
                        this.props.globalMapFullWidth && this.props.setGlobalMapFullWidth();
                    }
                    } to={`/project/${el.project_id}`}>Перейти к к проекту</Link> */}


                </Col>
            </Row>
        </div>;
    };
    render() {
        const ReactLeafletSearchComponent = withLeaflet(ReactLeafletSearch);
        const {
            center,
            points,
            activeAddItemType,
            currentSliderDateValue,
            zoom,
            loading,
            tasksWithMarkers
        } = this.state;
        return (<div>
            <Spin spinning={loading}>
                <Row>
                    <Col span={24} className="MapToolbar">
                        <Row className={'dFlexCenter'}>
                            <Col span={12}>
                                <Select
                                    style={{ width: "95%", textAlign: "left" }}
                                    placeholder="Фильтр по задачам"
                                    onChange={(e) => this.onTargetChangeTask(e)}
                                    showSearch
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {tasksWithMarkers && tasksWithMarkers.length > 0 && tasksWithMarkers.map((item) =>
                                        <Option value={item.id} key={item.id}>{item.name} </Option>
                                    )}
                                </Select>
                            </Col>


                            <Col span={4}>
                                <Select
                                    style={{ width: "95%", textAlign: "left" }}
                                    placeholder="Поиск по меткам"
                                    onChange={(e) => this.onTargetChange(e)}
                                    showSearch
                                    value={this.state.selectedMapObject}
                                    filterOption={(input, option) =>
                                        option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {points && points.length > 0 && points.map((item) =>
                                        <Option value={item.id} key={item.id}>{item.name} </Option>
                                    )}
                                </Select>
                            </Col>
                            <Col span={4} className={"actions"} style={{ float: 'left' }}>
                                <Button type={"link"} onClick={() => this.changeTool("point")}
                                    className={activeAddItemType.includes("point") && "activeBtnType"}><Pointer /></Button>
                                <Button type={"link"} onClick={() => this.changeTool("line")}
                                    className={activeAddItemType.includes("line") && "activeBtnType"}><Road /></Button>
                                <Button type={"link"} onClick={() => this.changeTool("poligon")}
                                    className={activeAddItemType.includes("poligon") && "activeBtnType"}><Poly /></Button>
                            </Col>
                            {/*             <Col span={6} offset={2}>
                                                    <Slider onChange={(e) => this.setState({ currentSliderDateValue: e })} step={null}
                        marks={dateTimelinePoints} min={min} max={max}
                        tipFormatter={(el) => moment(el).format("DD.MM.YYYY")}/>
                                </Col>*/}
                        </Row>

                        {/*
            {!!projectDetails && <Row gutter={16} className={"projectBlockOnMap"} style={{ paddingLeft: "12px" }}>

              <Col span={22}>
                <img className={'passportMapImg'} src={BackendUrl + projectDetails.avatar_src} alt={projectDetails.avatar}/>
                <h3>ПАСПОРТ ПРОЕКТА</h3>

                <h4>
                  <small>Название проекта</small>
                  <br/>
                  {projectDetails.name}
                </h4>

                <h4>
                  <small>Описание</small>
                  <br/>
                  {projectDetails.description}
                </h4>

                <h4>
                  <small>Срок проекта</small>
                  <br/>
                  {moment(projectDetails.start).format("DD.MM.YYYY")} -
                  {moment(projectDetails.end).format("DD.MM.YYYY")}
                </h4>

                <h4>
                  <small>Руководители проекта</small>
                  <br/>
                  {projectDetails.managers && projectDetails.managers.map(el => {
                    return <span>{el.last_name} {el.first_name} {el.middle_name ? el.middle_name : ""}<br/></span>;
                  })}
                </h4>
              </Col>

            </Row>}


            {!!taskDetails && <Row gutter={16} className={"projectBlockOnMap"} style={{ paddingLeft: "12px" }}>

              <Col span={22}>
                <h3>ПАСПОРТ ЗАДАЧИ</h3>

                <h4>
                  <small>Название проекта</small>
                  <br/>
                  {taskDetails.name}
                </h4>

                <h4>
                  <small>Описание</small>
                  <br/>
                  {taskDetails.description}
                </h4>

                <h4>
                  <small>Срок проекта</small>
                  <br/>
                  {moment(taskDetails.start).format("DD.MM.YYYY")} -
                  {moment(taskDetails.end).format("DD.MM.YYYY")}
                </h4>

                <h4>
                  <small>Ответственные задачи</small>
                  <br/>
                  {taskDetails.managers && taskDetails.managers.map(el => {
                    return <span>{el.last_name} {el.first_name} {el.middle_name ? el.middle_name : ""}<br/></span>;
                  })}
                </h4>
                <h4>
                  <small>Постановщик задачи</small>
                  <br/>
                  {taskDetails.members && taskDetails.members.map(el => {
                    return <span>{el.last_name} {el.first_name} {el.middle_name ? el.middle_name : ""}<br/></span>;
                  })}
                </h4>
              </Col>

            </Row>}*/}


                    </Col>
                    <Col span={24} className={"mapBlockEditor"}>
                        <Map
                            zoomControl={true}
                            center={center} zoom={zoom} className={"mapFullScreenGLobal_MainPage"}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            <ReactLeafletSearchComponent
                                customProvider={this.provider}
                                position="topleft"
                                inputPlaceholder="Поиск по карте"
                                search={[33.100745405144245, 46.48315429687501]}
                                showMarker={false}
                                zoom={12}
                                showPopup={true}
                                closeResultsOnClick={true}
                                openSearchOnLoad={false}
                                // // these searchbounds would limit results to only Turkey.
                                searchBounds={[
                                    [33.100745405144245, 46.48315429687501],
                                    [44.55916341529184, 24.510498046875]
                                ]}
                            />
                            {this.state.problems && this.state.problems.map((el, i) => {
                            if (el.map_object_json !== '[]') {
                                return <Marker position={JSON.parse(el.map_object_json)}
                                    key={i}
                                    onClick={() => this.onMarkerOpen(el)}
                                    icon={iconMarkerError}>
                                    <Popup>
                                        {this.renderPopup(el)}
                                    </Popup>
                                </Marker>;
                            }
                        })}
                            {points && points.map((el) => {
                                if ((el.type === "circle") && (activeAddItemType.includes("circle"))) {
                                    return <Circle center={el.center}
                                        key={el.id}
                                        color={"red"}
                                        draggable
                                        fillColor={this.state.selectedMapObject === el.id ? "#338DE0" : this.state.inputValue > el.color ? "green" : "red"}
                                        fillOpacity={0.5}
                                        radius={el.radius}
                                        onClick={() => this.onMarkerOpen(el)}>
                                        <Popup>
                                            {this.renderPopup(el)}
                                        </Popup>
                                    </Circle>;
                                } else if ((el.type === "line") && activeAddItemType.includes("line")) {
                                    return <Polyline positions={el.coordinates}
                                        key={el.id}
                                        onClick={() => this.onMarkerOpen(el)}
                                        color={this.state.selectedMapObject === el.id ? "#338DE0" : currentSliderDateValue >= moment(el.end_date).valueOf() ? "green" : "red"}>
                                        <Popup>
                                            {this.renderPopup(el)}
                                        </Popup>
                                    </Polyline>;
                                } else if ((el.type === "poligon") && activeAddItemType.includes("poligon")) {
                                    return <Polygon positions={el.coordinates}
                                        key={el.id}
                                        onClick={() => this.onMarkerOpen(el)}
                                        color={this.state.selectedMapObject === el.id ? "#338DE0" : currentSliderDateValue >= moment(el.end_date).valueOf() ? "green" : "red"}>
                                        <Popup>
                                            {this.renderPopup(el)}
                                        </Popup>
                                    </Polygon>;
                                } else if ((el.type === "point") && activeAddItemType.includes("point")) {
                                    return <Marker position={el.coordinates}
                                        key={el.id}
                                        onClick={() => this.onMarkerOpen(el)}
                                        icon={this.renderIconFunc(el.icon_pointer)}>
                                        <Popup>
                                            {this.renderPopup(el)}
                                        </Popup>
                                    </Marker>;
                                } else {
                                    return null;
                                }
                            })}
                        </Map>
                    </Col>
                </Row>
            </Spin>

        </div>
        );
    }
}

export default GlobalMapOnMainPanel;
