import React,{useState} from 'react'
import {Doughnut} from 'react-chartjs-2'

function DoughnutChart(props) {
    const {wid} = props
    const [dataArray,setDataArray] = useState([''])
    setDataArray(wid)
    const data = {
        labels: ['Jan','Jul','Aug','March','May',],
        datasets: [
            {
                label: 'Salesfor 20020',
                data: dataArray,
                borderColor: ['rgba(255,206,86,0.2)','rgba(251,206,86,0.2)','rgba(255,206,86,0.2)','rgba(255,206,86,0.2)','rgba(255,206,86,0.2)'],
                backgroundColor: ['rgba(25,206,86,0.2)','rgba(255,206,86,0.2)','rgba(255,206,86,0.2)','rgba(255,206,86,0.2)','rgba(255,206,86,0.2)'],
                
            }
        ]
    }
    const options = {
        title: {
            display: true,
            text: 'Doughnut Chart'
        },
        radius: 150
    }
    return (
        <Doughnut data={data} options={options} />
    )
}

export default DoughnutChart
