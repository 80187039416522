import React, { Component } from "react";
import { Row, Col, Select, Button } from "antd";
import { ReactComponent as Prev } from "../../assets/images/icons/prev.svg";
import { ReactComponent as Next } from "../../assets/images/icons/next.svg";

const { Option } = Select;

class VpPagination extends Component {
  countChange = e => {
    this.props.changeCountPage(e);
  };

  render() {
    const { total, from, to, current_page, last_page } = this.props;
    return (
      <div className={"VpPagination"}>
        <Row>
          <Col span={12} className={"text-left"}>
            <div className={"counterBlock"}>
              <span className={"secondary-color"}>Показано пунктов: </span>
              <Select defaultValue="15" className={"border-none"} style={{ width: 60 }}
                      onChange={(e) => this.countChange(e)}>
                <Option value="5">5</Option>
                <Option value="10">10</Option>
                <Option value="15">15</Option>
                <Option value="25">25</Option>
                <Option value="50">50</Option>
                <Option value="99">99</Option>
              </Select>
            </div>

            <span>{from}-{to} из {total} пунктов</span>
          </Col>
          <Col span={12} className={"text-right"}>
            <div className={"pageCounter"}>
              {current_page} из {last_page} страниц
            </div>
            <div className={"navigationPag"}>
              <Button onClick={()=> this.props.changePage(current_page - 1)} disabled={current_page === 1} type={"link"}><Prev/></Button>
              <Button onClick={()=> this.props.changePage(current_page + 1)} disabled={current_page === last_page} type={"link"}><Next/></Button>
            </div>

          </Col>
        </Row>
      </div>
    );
  }
}

export default VpPagination;
