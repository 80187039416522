import React, { Component } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
import {agentRegister } from "../../actions/mainActions";


const layout = {
  labelCol: {
    span: 2
  },
  wrapperCol: {
    span: 24
  }
};
const tailLayout = {
  wrapperCol: {
    span: 4
  }
};

export class RegistrationAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false
    };
  }

  componentDidMount() {

  }

  onFinishFailed = () => {
    message.error("Заполните корректно данные регистрации");
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true
    });
    agentRegister(e).then((response) => {
      if(this.props.role === 'root') {
        this.props.updateList();
        this.setState({
          loading: false
        });
      } else {
      message.success("Успешно зарегистрированы");
      message.success("Ожидайте активации учетной записи администратором", 10);
      this.props.parentProps.history.push("/auth");}
    }, err => {
      this.setState({
        loading: false
      });
    });
  };


  render() {
    const { loading } = this.state;
    return (
      <Form
        className={"regForm"}
        {...layout}
        name="basic"
        onFinish={this.handleSubmit}
        onFinishFailed={this.onFinishFailed}
      >

        <Row gutter={24}>
          <Col span={12}>
            <h4>Наименование компании</h4>
            <Form.Item
              name="legal_name"
              rules={[
                {
                  required: true,
                  message: "Введите наименование компании"
                }
              ]}
            >
              <Input placeholder='Наименование компании' size='large'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h4>БИН</h4>
            <Form.Item
              name="bin"
              rules={[
                {
                  required: true,
                  message: "Введите БИН, 12 символов"
                }
              ]}
            >
              <Input placeholder='Введите БИН' size='large' minLength={12} maxLength={12}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <h4>Имя</h4>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Введите имя"
                }
              ]}
            >
              <Input placeholder='Введите имя' size='large'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h4>Email</h4>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Введите email"
                }
              ]}
            >
              <Input placeholder='Введите email' size='large'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <h4>Фамилия</h4>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Введите фамилию"
                }
              ]}
            >
              <Input placeholder='Введите фамилию' size='large'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h4>Пароль</h4>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Введите пароль"
                }
              ]}
            >
              <Input.Password placeholder='Введите пароль' size='large'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <h4>Отчество</h4>
            <Form.Item
              name="middle_name"
              rules={[
                {
                  required: false,
                  message: "Введите отчество"
                }
              ]}
            >
              <Input placeholder='Введите отчество' size='large'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h4>Повторите пароль</h4>
            <Form.Item
              name="c_password"
              rules={[
                {
                  required: true,
                  message: "Введите пароль"
                }
              ]}
            >
              <Input.Password placeholder='Повторите пароль' size='large'/>
            </Form.Item>
          </Col>
        </Row>

        {/*     <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                    <Checkbox style={{float: 'left'}}>Запомнить пароль</Checkbox>
                </Form.Item>*/}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" size='large'
                  loading={loading}
                  style={{ width: "250px", float: "left" }}>
            Отправить запрос
          </Button>
        </Form.Item>
        <Link to='/auth' style={{ float: "left", marginBottom: "85px" }}>
          <span style={{ fontSize: "20px", textDecoration: "underline" }}>Вход</span>
        </Link>
      </Form>
    );
  }
}


export default RegistrationAgent;
