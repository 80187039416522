import React, { Component } from "react";
import { Map, Marker, TileLayer, withLeaflet } from "react-leaflet";
import { ReactLeafletSearch } from "react-leaflet-search";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "antd/dist/antd.css";
import { Col, Row, Button } from "antd";
import { getProject, getTask } from "../../actions/mainActions";
import { iconMarkerError } from "./globalMap";



class ErrorMap extends Component {
  state = {
    projectDetails: null,
    taskDetails: null,
    currentSliderDateValue: 0,
    min: 0,
    max: 0,
    dateTimelinePoints: {},
    selectedMapObject: null,
    pointersId: [],
    loading: false,
    showHelpTips: true,
    activeAddItemType: ["point", "line", "poligon"],
    inputValue: 1,
    center: {
      lat: 51.167347,
      lng: 71.561905
    },
    points: [],
    newMapObject: {
      title: "",
      description: "",
      coordinates: [],
      type: ""
    }
  };
  onChange = value => {
    this.setState({
      inputValue: value
    });
  };

  onTargetChange = (id) => {
    const object = this.state.points.find(el => el.id === id);
    const newCenter = [object.coordinatesArr[0].latitude, object.coordinatesArr[0].longitude];
    this.onMarkerOpen(object);
    this.setState({
      center: newCenter
    });
  };

  onMarkerOpen = (el) => {
    this.setState({
      selectedMapObject: el.id
    });

    if (!!el.project_id) {
      getProject(el.project_id).then(res => {
          this.setState({
            projectDetails: res.data.data
          });
        },
        err => {
          this.setState({
            projectDetails: null
          });
        }
      );
    } else {
      this.setState({
        projectDetails: null
      });
    }

    getTask(el.task_id).then(res => {
        this.setState({
          taskDetails: res.data.data
        });
      }, err => {
        this.setState({
          taskDetails: null
        });
      }
    );
  };

  addMarker = (e) => {
    const {newMapObject } = this.state;
    const copyMapObject = { ...newMapObject };
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;
    copyMapObject.coordinates = [lat, lng];
    copyMapObject.type = "error";
    this.setState({ points: [copyMapObject] });
    this.props.setNewErrorPoint(copyMapObject.coordinates);
  };

  deleteMarker = () => {
    this.setState({ points: [] });
    this.props.setNewErrorPoint([]);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.coords !== prevProps.coords) {
      this.setExistPoint();
    }
  }
  setExistPoint = () => {
    const {newMapObject} = this.state;
    const copyMapObject = {...newMapObject};
    copyMapObject.coordinates = this.props.coords;
    copyMapObject.coordinates.length > 0 ? this.setState((s)=>({
      ...s,
      points: [copyMapObject],
      center: this.props.coords
    })) : this.setState({
      points: [],
    });
  }

  render() {
    const ReactLeafletSearchComponent = withLeaflet(ReactLeafletSearch);
    const { center, points } = this.state;
    return (<div>
        <Row>
          <Col span={24} className={"mapBlockEditor"}>
            <h3>Указать на карте {this.state.points && this.state.points.length > 0 && <Button type={'link'}  onClick={()=>this.deleteMarker()}>Удалить метку</Button>}</h3>
            <Map
              onClick={this.addMarker}
              zoomControl={true}
              center={center} zoom={14} className={"errorMap"}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <ReactLeafletSearchComponent
                customProvider={this.provider}
                position="topleft"
                inputPlaceholder="Поиск по карте"
                search={[33.100745405144245, 46.48315429687501]}
                showMarker={false}
                zoom={12}
                showPopup={true}
                closeResultsOnClick={true}
                openSearchOnLoad={false}
                // // these searchbounds would limit results to only Turkey.
                searchBounds={[
                  [33.100745405144245, 46.48315429687501],
                  [44.55916341529184, 24.510498046875]
                ]}
              />

              {points && points.map(el =>
                <Marker key={el.id} position={el.coordinates} icon={iconMarkerError}>
                </Marker>)
              }
            </Map>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ErrorMap;
