import React from "react";
import {Component} from "react";
import {Button, message, Modal, Tabs} from "antd";
import {
    PlusCircleOutlined
} from "@ant-design/icons";
import ContractorsList from "./contractorsList";
import UsersList from "./usersList";
import AllUsersList from "./allUsersList";
import RegisterMain from "../../Registration";
import AllContrAgents from "./allContrAgents";

const {TabPane} = Tabs;

class AdminPanel extends Component {
    state = {
        updater: 0,
        tab: '2',
        visibleRegModal: false
    };
    handleCancel = () => {
        this.setState({
            visibleRegModal: false
        })
    }
    callback = (e) => {
        this.setState({
            tab: e
        });
    };
    showModal = () => {
        this.setState({
            visibleRegModal: true
        })
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if ((user.role !== 'root') && (user.role !== 'admin')) {
            message.error('Недостаточно прав, для доступа к странице');
            this.props.parentProps.history.push('/panel');
        }
    }


    updateList = () => {
        this.handleCancel();
        this.setState({
            updater: 1 + this.state.updater
        })

    }

    render() {
        return (
            <div>
                <div style={{overflow: "auto"}}>
                    <h2 className='componentTitle float-left'>Пользователи</h2>
                    {this.props.role === 'root' && <Button
                        style={{marginLeft: "100px"}}
                        type="primary"
                        className={"float-left"}
                        size="large"
                        onClick={this.showModal}
                    >
                        Добавить пользователя<PlusCircleOutlined/>
                    </Button>}
                </div>
                <Tabs defaultActiveKey="2" onChange={(e) => this.callback(e)} style={{marginTop: "15px"}}>
                    <TabPane tab="Запросы на регистрацию" key="2">
                        {this.state.tab === '2' &&  <UsersList updater={this.state.updater}/>}
                    </TabPane>

                    {this.props.role === 'root' && <TabPane tab="Запросы на регистрацию контрагента" key="4">
                        {this.state.tab === '4' &&  <ContractorsList updater={this.state.updater}/>}
                    </TabPane>}
                    {this.props.role === 'root' && <TabPane tab="Все контрагенты" key="3">
                        {this.state.tab === '3' && <AllContrAgents updater={this.state.updater}/>}
                    </TabPane>}


                    {(this.props.role === 'root' || this.props.role === 'admin') &&
                    <TabPane tab="Все пользователи" key="1">
                        {this.state.tab === '1' && <AllUsersList updater={this.state.updater}/>}
                    </TabPane>}
                </Tabs>
                <Modal
                    title={'Регистрация пользователя'}
                    centered
                    footer={null}
                    width={"60%"}
                    visible={this.state.visibleRegModal}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                >
                    <RegisterMain role={this.props.role} updateList={this.updateList}/>
                </Modal>
            </div>);
    }
}

export default AdminPanel;
