import React, { Component } from "react";
import { Steps, Spin, Form, Row, Col, Input, Upload, Button, Empty, message } from 'antd';
import {ReactComponent as RadioButton} from "../../assets/images/icons/radioButton.svg";
import {ReactComponent as Basket} from "../../assets/images/icons/basket.svg";
import {ReactComponent as Bed} from "../../assets/images/icons/bed.svg";
import {ReactComponent as Temperature} from "../../assets/images/icons/temperature.svg";
import {ReactComponent as Size} from "../../assets/images/icons/building_size.svg";
import { DeleteOutlined } from '@ant-design/icons';
import {
  BackendUrl,
  getInvestProjectRegions,
  getInvestProjectCategories,
  getInvestProjectTypes,
  getInvestProjectKinds,
  getInvestProjectRequiredBuildings,
  createInvestProject,
  addFile,
  deleteFile
} from "../../actions/mainActions";
import CenteringMap from "../ProjectsMap/centeringMap";

class CreateInvestProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      progress: 0,
      regionsData: [],
      selectedRegionId: null,
      categoriesData: [],
      selectedCategoryId: null,
      typesData: null,
      selectedTypeId: null,
      selectedTypeName: null,
      kindsData: null,
      requiredBuildingsData: null,
      basket: [],
      uniqueBasket: [],
      filesList: [],
      picturesList: [],
      coords: [],
      status: 0,
      requestLoading: false,
      saveLoading: false
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    getInvestProjectRegions().then(response => {
      this.setState({
        regionsData: response.data.data
      })
    })
  }

  nextTab = () => {
    this.setState({
      current: this.state.current + 1,
      progress: this.state.current + 1
    })
  }

  onRegionSelected = regionId => {
    getInvestProjectCategories().then(response => {
      this.setState({
        categoriesData: response.data.data,
        selectedRegionId: regionId
      })
    })
    this.nextTab()
  }
  onCategorySelected = categoryId => {
    getInvestProjectTypes(categoryId).then(response => {
      this.setState({
        typesData: response.data.data,
        selectedCategoryId: categoryId
      })
    })
    this.nextTab()
  }
  onTypeSelected = type => {
    getInvestProjectKinds(type.id).then(response => {
      this.setState({
        kindsData: response.data.data,
        selectedTypeId: type.id,
        selectedTypeName: type.name,
        current: 3,
        progress: 3
      })
    })
  }
  onKindSelected = kind => {
    this.setState({
      basket: [...this.state.basket, kind]
    })
  }

  onGoBasket = () => {
    const uniqueItemsCountArray = {}
    const uniqueItemsAndCount = []
    this.state.basket.forEach(item => { uniqueItemsCountArray[item.id] = (uniqueItemsCountArray[item.id] || 0) + 1; });
    Array.from(new Set(this.state.basket)).forEach(item => {
      uniqueItemsAndCount.push({
        count: uniqueItemsCountArray[item.id],
        data: item
      })
    })
    getInvestProjectRequiredBuildings(this.state.selectedTypeId).then(response => {
      this.setState({
        uniqueBasket: uniqueItemsAndCount,
        requiredBuildingsData: response.data.data
      })
    })
    this.nextTab()
  }

  deleteItemFromBasket = item => {
    const index = this.state.basket.indexOf(item)
    this.state.basket.splice(index, 1)
    this.setState({
      basket: this.state.basket
    })
  }

  uploadFile = ({ file }) => {
    const formData = new FormData();
    formData.append("files[]", file);
    addFile(formData).then((res) => {
      const filesList = {
        uid: file.uid,
        name: file.name,
        status: "done",
        response: res.data.data.ids[0],
        url: BackendUrl + res.data.data.src[0],
      };
      this.setState({
        filesList: [...this.state.filesList, filesList]
      })
    })
  }
  uploadPicture = ({ file }) => {
    // const formData = new FormData();
    // formData.append("files[]", file);
    // addFile(formData).then((res) => {
    //   const picturesList = this.state.picturesList;
    //   picturesList.push({
    //     uid: file.uid,
    //     name: file.name,
    //     status: "done",
    //     response: res.data.data.ids[0],
    //     url: BackendUrl + res.data.data.src[0],
    //   });
      this.setState({
        picturesList: [...this.state.picturesList, file]
      })
    // })
  }

  removeFile = file => {
    const filesList = [...this.state.filesList];
    const index = filesList.findIndex((el) => el.uid === file.uid);
    deleteFile(file.response).then((res) => {
      if (index > -1) {
        filesList.splice(index, 1);
      }
      this.setState({
        filesList: filesList,
      });
    });
  }
  removePicture = file => {
    const picturesList = [...this.state.picturesList];
    const index = picturesList.findIndex((el) => el.uid === file.uid);
    deleteFile(file.response).then((res) => {
      if (index > -1) {
        picturesList.splice(index, 1);
      }
      this.setState({
        picturesList,
      });
    });
  }

  onFinish = data => {
    this.setState({
      saveLoading: true
    })
    const formData = new FormData()
    formData.set('name', data.name)
    formData.set('description', data.description)
    this.state.filesList.length && this.state.filesList.forEach(file => {
      formData.append('files[]', file.response)
    })
    data.images && data.images.fileList.forEach(image => {
      formData.append('images[]', image.originFileObj ?? image)
    })
    this.state.uniqueBasket.forEach(item => {
      formData.append(`items[${item.data.id}]`, item.count)
    })
    formData.set('region_id', this.state.selectedRegionId)
    formData.set('category_id', this.state.selectedCategoryId)
    if (this.state.coords.length) {
      formData.set('coordinates[0]', this.state.coords[0])
      formData.set('coordinates[1]', this.state.coords[1])
    }
    formData.set('status', 3)
    
    createInvestProject(formData).then(response => {
      message.success("Проект успешно создан")
      this.props.parentProps.history.push('/investment/projects')
    })
  }

  onFinishWithRequest = () => {
    this.setState({
      requestLoading: true
    })
    const data = this.formRef.current.getFieldsValue()
    this.formRef.current.validateFields().then(res => {
      const formData = new FormData()
      formData.set('name', data.name)
      formData.set('description', data.description)
      this.state.filesList.length && this.state.filesList.forEach(file => {
        formData.append('files[]', file.response)
      })
      data.images && data.images.fileList.forEach(image => {
        formData.append('images[]', image.originFileObj ?? image)
      })
      this.state.uniqueBasket.forEach(item => {
        formData.append(`items[${item.data.id}]`, item.count)
      })
      formData.set('region_id', this.state.selectedRegionId)
      formData.set('category_id', this.state.selectedCategoryId)
      if (this.state.coords.length) {
        formData.set('coordinates[0]', this.state.coords[0])
        formData.set('coordinates[1]', this.state.coords[1])
      }
      formData.set('status', 0)
      
      createInvestProject(formData).then(response => {
        message.success("Проект успешно создан")
        this.props.parentProps.history.push('/investment/projects')
      })
    }, err => err.errorFields.forEach(error => message.error(error.errors[0]))
    )
  }

  onCancel = () => {
    this.props.parentProps.history.push('/investment/projects')
  }

  setNewCenterPoint = coords => {
    this.setState({
      coords
    })
  }
  
  render() {
    const { Step } = Steps
    const { TextArea } = Input
    const changeTab = tabNumber => {
      this.setState({
        current: tabNumber
      })
    }

    const totalSumCount = () => {
      let sum = 0
      this.state.uniqueBasket.forEach(item => {
        sum = sum + item.data.price * item.count
      })
      this.state.requiredBuildingsData && this.state.requiredBuildingsData.forEach(item =>{
        sum = sum + item.price
      })
      return formatToCurrnecy(sum)
    }

    const formatToCurrnecy = number => {
      return new Intl.NumberFormat('ru-RU').format(number) + ' тг'
    }

    return (
      <div className='create-invest-project'>
        <div className="title">
          <h1>Создать проект</h1>
          {
            this.state.progress === 0 &&
            <p>Вам понадобится примерно 50 минут для заполнения проекта. Просим запланировать ваше время</p>
          }
          <div className="steps">
            <Steps current={this.state.progress}>
              <Step icon={<RadioButton />} onClick={() => { this.state.progress > 0 && changeTab(0) }} />
              <Step icon={<RadioButton />} onClick={() => { this.state.progress >= 1 && changeTab(1) }} />
              <Step icon={<RadioButton />} onClick={() => { this.state.progress >= 2 && changeTab(2) }} />
              <Step icon={<RadioButton />} onClick={() => { this.state.progress >= 3 && changeTab(3) }} />
              <Step icon={<RadioButton />} onClick={() => { this.state.progress >= 4 && changeTab(4) }} />
            </Steps>
          </div>
        </div>
        <section className={this.state.basket.length && this.state.current !== 4 ? 'with-cart' : ''}>
          {
            this.state.current === 0 &&
            <div>
              <h3>Выберите регион</h3>
              {
                this.state.regionsData.length > 0 ?
                <div className='grid regions'>
                  {
                    this.state.regionsData.map(region => (
                      <div key={region.id} onClick={() => this.onRegionSelected(region.id)} className="image-card">
                        <span>{region.name}</span>
                        <img src={`${BackendUrl}${region.image_path}`} alt={region.name}/>
                      </div>
                    ))
                  }
                </div>
                :
                <Spin/>
              }
            </div>
          }
          {
            this.state.current === 1 &&
            <div>
              <h3>Выберите категорию проекта</h3>
              {
                this.state.categoriesData.length > 0 ? 
                <div className="grid categories">
                  {
                    this.state.categoriesData.map(category => (
                      <div key={category.id} onClick={() => this.onCategorySelected(category.id)} className="image-card">
                        <span>{category.name}</span>
                        <img src={`${BackendUrl}${category.image_path}`} alt={category.name}/>
                      </div>
                    ))
                  }
                </div>
                :
                <Spin/>
              }
            </div>
          }
          {
            
            (this.state.current === 2 || this.state.current === 3) &&
            <div>
              <h3>Выберите тип</h3>
              {
                this.state.typesData ? 
                <div className="grid types">
                  {
                    this.state.typesData.length > 0 ? this.state.typesData.map(type => (
                      <div key={type.id} onClick={() => this.onTypeSelected(type)} className="image-card">
                        <span>{type.name}</span>
                        <img src={`${BackendUrl}${type.image_path}`} alt={type.name}/>
                      </div>
                    )) : <Empty description={'Нет данных'} style={{gridColumn: '1 / 5'}}/>
                  }
                </div>
                :
                <Spin/>
              }
            </div>
          }
          {
            
            this.state.current === 3 &&
            <div>
              <h3>{this.state.selectedTypeName}</h3>
              {
                this.state.kindsData ? 
                <div className="grid kinds">
                  {
                    this.state.kindsData.length > 0 ? this.state.kindsData.map(kind => (
                      <div key={kind.id} className="kind-card">
                        <img src={`${BackendUrl}${kind.image_path}`} alt={kind.name}/>
                        <div className="kind-data">
                          <div className='name'>{kind.name}</div>
                          <div className='beds'><Bed/>{kind.beds}</div>
                          <div className='temperature'><Temperature/>{kind.temperature}</div>
                          <div className='size'><Size/>{kind.size} м<sup>2</sup></div>
                          <div className='price'>
                            {formatToCurrnecy(kind.price)}
                            <button onClick={() => this.onKindSelected(kind)}><Basket/></button>
                          </div>
                        </div>
                      </div>
                    )) : <Empty description={'Нет данных'} style={{gridColumn: '1 / 25'}}/>
                  }
                </div>
                :
                <Spin/>
              }
            </div>
          }
          {
            this.state.current === 3 && this.state.basket.length > 0 &&
            <aside>
              <h2>Корзина</h2>
              {
                this.state.basket.map(item => 
                  <div className="kind-card">
                    <img src={`${BackendUrl}${item.image_path}`} alt={item.name}/>
                    <div className="kind-data">
                      <div className='name'>{item.name}</div>
                      <div className='beds'><Bed/>{item.beds}</div>
                      <div className='temperature'><Temperature/>{item.temperature}</div>
                      <div className='size'><Size/>{item.size} м<sup>2</sup></div>
                      <div className='price'>
                        {formatToCurrnecy(item.price)}
                        <button className="delete-cart-item" onClick={() => this.deleteItemFromBasket(item)}>Удалить <DeleteOutlined /></button>
                      </div>
                    </div>
                  </div>
                )
              }
              <button className="go-to-basket" onClick={() => this.onGoBasket()}>
                ПЕРЕЙТИ В КОРЗИНУ
              </button>
            </aside>
          }
          {
            this.state.current === 4 &&
            <div>
              <h3>Ваш выбор</h3>
              <Col span={20} className="basket">
                {
                  this.state.uniqueBasket.map(item => 
                    <div className="basket-item" key={item.data.id}>
                      <div className="basket-item-top">
                        <img src={BackendUrl + item.data.image_path} alt={item.data.name} />
                        <div className="basket-item-top-right">
                          <div className="basket-item-data">
                            <span className="name">{item.data.name}</span>
                            <span className="beds"><span>Вместимость: </span>{item.data.beds} человек</span>
                            <span className="temperature"><span>Температура: </span>{item.data.temperature}</span>
                            <span className="square"><span>Площадь пола: </span>{item.data.size} м<sup>2</sup></span>
                          </div>
                          <div className="basket-item-count-and-price">
                            <span>{formatToCurrnecy(item.data.price * item.count)}</span>
                            <span>Количество: {item.count}</span>
                          </div>
                        </div>
                      </div>
                      <div className="basket-item-description">
                        {item.data.description}
                      </div>
                    </div>
                  )
                }
              </Col>
              <h3>Обязательные постройки</h3>
              <Col span={20} className="required-buildings">
                {
                  this.state.requiredBuildingsData && this.state.requiredBuildingsData.length ?
                  this.state.requiredBuildingsData.map(building => 
                    <div className='required-building' key={building.id}>
                      <img src={BackendUrl + building.image_path} alt="" />
                      <div>
                        <h4>{building.name} {formatToCurrnecy(building.price)}</h4>
                        <p>{building.description}</p>
                      </div>
                    </div>
                  )
                  : <h4>Обязательных построек нет</h4>
                }
              </Col>
              <h3 className="total">Итого: {`${totalSumCount()}`}</h3>
              <Form
                onFinish={this.onFinish}
                ref={this.formRef}
              >
                <Row>
                  <Col span={5}>
                    <h4>Название проекта</h4>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Введите имя проекта",
                        }
                      ]}
                    >
                      <Input placeholder="Введите название проекта" size="large" className="my-ant-input" style={{width: '100%'}}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>
                    <h4>Краткое описание</h4>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Введите описание проекта",
                        }
                      ]}
                    >
                      <TextArea placeholder="Введите текст" className="my-ant-textarea"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>
                    <h4>Документы к проекту</h4>
                  </Col>
                  <Col>
                    <Form.Item  name="files">
                      <Upload
                        onRemove={this.removeFile}
                        listType="text"
                        fileList={this.state.filesList}
                        customRequest={this.uploadFile}
                      >
                        {this.state.filesList.length >= 8 ? null : (
                          <Button style={{border: '1px solid', borderRadius: '8px'}} size="large" type={"link"}>
                            + Загрузить документ
                          </Button>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{zIndex: '0', position: 'relative'}}>
                  <Col span={15}>
                  <CenteringMap
                    setNewCenterPoint={this.setNewCenterPoint}
                    coords={this.state.coords}
                  />
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>
                    <h4>Фотографии проекта</h4>
                  </Col>
                  <Col>
                    <Form.Item name="images">
                      <Upload
                        onRemove={this.removePicture}
                        listType="picture"
                        fileList={this.state.picturesList}
                        customRequest={this.uploadPicture}
                        multiple
                      >
                        {this.state.filesList.length >= 8 ? null : (
                          <Button style={{border: '1px solid', borderRadius: '8px'}} size="large" type={"link"}>
                            + Загрузить фотографию
                          </Button>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{columnGap: '15px'}}>
                  <Form.Item>
                    <Button 
                      htmlType="submit"
                      disabled={this.state.requestLoading}
                      loading={this.state.saveLoading}
                      size="large"
                      type="primary"
                    >
                      СОХРАНИТЬ
                    </Button>
                  </Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    disabled={this.state.saveLoading}
                    loading={this.state.requestLoading}
                    className="success-button"
                    onClick={this.onFinishWithRequest}
                  >
                    ПОДАТЬ ЗАЯВКУ
                  </Button>
                  <Button
                    size="large"
                    type="link"
                    loading={this.state.requestLoading}
                    className="cancel-button"
                    onClick={this.onCancel}
                  >
                    ОТМЕНА
                  </Button>
                </Row>
              </Form>
            </div>
          }
        </section>
      </div>
    )
  }
}

export default CreateInvestProject